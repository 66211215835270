<script setup>
import { ref, watch } from 'vue';
import axios from 'axios';
import ETHYingliDizhi from './ETHYingliDizhi.json';
import ETHtokenAvatars from './ETHtokenAvatars.json';
const { GoPlus, ErrorCode } = require("@goplus/sdk-node");

const props = defineProps({
  poolAddress: {
    type: String,
    required: true,
  },
});

const selectedPoolCommunityContent = ref([]);
const loading = ref(false);
const tokenAddress = ref({ address: '', symbol: '' });
const tokenSecurity = ref(null);

const formatAmount = (amount) => {
  if (amount >= 1e9) return (amount / 1e9).toFixed(2) + 'B';
  if (amount >= 1e6) return (amount / 1e6).toFixed(2) + 'M';
  if (amount >= 1e3) return (amount / 1e3).toFixed(2) + 'K';
  return amount.toFixed(2);
};

const getTokenAddressFromPool = async (poolAddress) => {
  const url = `https://api.dexscreener.com/latest/dex/pairs/base/${poolAddress}`;
  try {
    const response = await axios.get(url);
    const baseTokenAddress = response.data.pairs[0].baseToken.address;
    const symbol = response.data.pairs[0].baseToken.symbol;
    const priceUsd = parseFloat(response.data.pairs[0].priceUsd);
    return { tokenAddress: baseTokenAddress, symbol, priceUsd };
  } catch (error) {
    console.error('Error fetching token address from pool:', error);
    throw error;
  }
};

const fetchTokenSecurity = async (token) => {
  let chainId = "8453";
  let addresses = [token].map(address => address.toLowerCase());
  try {
    let res = await GoPlus.tokenSecurity(chainId, addresses, 30);
    if (res.code !== ErrorCode.SUCCESS) {
      console.error(res.message);
      return null;
    } else {
      const result = res.result[addresses[0]];
      return {
        holder_count: parseInt(result.holder_count),
        total_supply: parseFloat(result.total_supply),
      };
    }
  } catch (error) {
    console.error('Error fetching token security:', error);
    return null;
  }
};

const fetchTokenHolders = async (tokenAddress) => {
  const options = {
    method: 'GET',
    url: 'https://api.chainbase.online/v1/token/top-holders',
    headers: {
      'x-api-key': '2VOKejecXTzPgIgBNRPjv3q9bRf'
    },
    params: {
      chain_id: 1,
      contract_address: tokenAddress,
      limit: 100
    }
  };

  const allData = [];
  for (let page = 1; page <= 5; page++) {
    const response = await axios({ ...options, params: { ...options.params, page } });
    allData.push(...response.data.data);
  }

  return allData;
};

const fetchAndDisplayTokenHolders = async (poolAddress) => {
  loading.value = true;
  selectedPoolCommunityContent.value = [];

  try {
    const {tokenAddress: address, symbol, priceUsd} = await getTokenAddressFromPool(poolAddress);
    tokenAddress.value = {address, symbol};

    const securityInfo = await fetchTokenSecurity(address);
    if (securityInfo) {
      tokenSecurity.value = securityInfo;
    }

    const holders = await fetchTokenHolders(address);
    const totalSupply = tokenSecurity.value ? tokenSecurity.value.total_supply : holders.reduce((sum, holder) => sum + parseFloat(holder.amount), 0);

    const communityHoldings = {};

    holders.forEach((holder) => {
      const holderAddress = holder.wallet_address;
      const communities = ETHYingliDizhi[holderAddress] || [];

      if (communities.length > 0 && !communities.includes(symbol)) {
        const amount = parseFloat(holder.amount);
        const holdingPercentage = ((amount / totalSupply) * 100).toFixed(2);
        const holdingValue = (amount * priceUsd).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

        communities.forEach(community => {
          if (!communityHoldings[community]) {
            communityHoldings[community] = {
              name: community,
              addresses: [],
              totalAmount: 0,
              totalHoldingValue: 0,
              percentage: 0,
            };
          }

          communityHoldings[community].addresses.push(holderAddress);
          communityHoldings[community].totalAmount += amount;
          communityHoldings[community].totalHoldingValue += amount * priceUsd;
          communityHoldings[community].percentage += parseFloat(holdingPercentage);
        });
      }
    });

    selectedPoolCommunityContent.value = Object.values(communityHoldings).map(community => ({
      ...community,
      totalAmount: formatAmount(community.totalAmount),
      totalHoldingValue: community.totalHoldingValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      percentage: (community.totalAmount / totalSupply * 100).toFixed(2),
    }));

  } catch (error) {
    console.error('Error fetching token holders:', error);
  } finally {
    loading.value = false;
  }
};

// Call the function to fetch and display data
fetchAndDisplayTokenHolders(props.poolAddress);

watch(() => props.poolAddress, (newAddress) => {
  if (newAddress && !loading.value) {
    fetchAndDisplayTokenHolders(newAddress);
  }
});
</script>

<template>
  <div class="p-3">
    <div v-if="loading" class="text-center text-gray-500">Loading...</div>
    <div v-else>
      <!-- Token info -->
      <div v-if="tokenSecurity" class="mb-4">
        <p>Total Supply: {{ formatAmount(tokenSecurity.total_supply) }}</p>
        <p>Holder Count: {{ tokenSecurity.holder_count }}</p>
      </div>

      <!-- Title bar -->
      <div class="bg-gray-200 p-1 rounded-lg shadow-lg flex items-center font-bold">
        <div class="w-1/4 text-left text-gray-600">Community</div>
        <div class="w-1/6 text-gray-600">Quality Members</div>
        <div class="w-1/6 text-gray-600">Holdings</div>
        <div class="w-1/6 text-gray-600">Value</div>
        <div class="w-1/6 text-gray-600">Percentage</div>
      </div>

      <div class="space-y-2 max-h-96 overflow-y-auto mt-2">
        <div
            v-for="(community, index) in selectedPoolCommunityContent"
            :key="index"
            class="bg-white p-1 rounded-lg shadow-lg hover:shadow-xl transition-shadow flex items-center"
        >
          <!-- Avatar and name -->
          <div class="flex items-center space-x-4 w-1/4">
            <img
                :src="ETHtokenAvatars[community.name] || 'default-avatar.png'"
                alt="Avatar"
                class="w-8 h-8 rounded-full border-2 border-gray-300"
            />
            <p class="text-lg font-bold text-gray-900">{{ community.name }}</p>
          </div>

          <!-- Member count -->
          <div class="w-1/6">
            <p class="text-sm text-blue-600 font-medium">{{ community.addresses.length }}</p>
          </div>

          <!-- Holdings -->
          <div class="w-1/6">
            <p class="text-sm text-blue-600 font-medium">{{ community.totalAmount }}</p>
          </div>

          <!-- Value -->
          <div class="w-1/6">
            <p class="text-sm text-green-700 font-medium">${{ community.totalHoldingValue }}</p>
          </div>

          <!-- Percentage -->
          <div class="w-1/6">
            <p class="text-sm text-purple-600 font-medium">{{ community.percentage }}%</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* You can add additional styles here */
</style>