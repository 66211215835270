<template>
  <div class="relative inline-flex items-center">
    <button @click="toggleModal" class="wallet-button flex items-center">
      <div class="w-[180px] h-8 flex items-center justify-between">
        <img :src="selected.avatar" alt="" class="h-8 w-full object-cover" />
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2 text-gray-400 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </div>
    </button>

    <!-- Network Selection Modal -->
    <div v-if="showModal" class="network-modal">
      <div class="network-modal-content relative">
        <button @click="toggleModal" class="close-icon absolute top-2 right-2">
          &times;
        </button>
        <h3 class="text-xl font-bold mb-4">选择网络</h3>
        <div class="network-options">
          <button
              v-for="network in people"
              :key="network.id"
              @click="selectNetwork(network)"
              class="network-option"
          >
            <div class="image-container">
              <img :src="network.avatar" :alt="network.name" class="network-image" />
            </div>
            <span class="network-name">{{ network.name }}</span>
          </button>
        </div>
        <button @click="toggleModal" class="close-modal-button mt-6">取消</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, inject } from 'vue'

const store = inject('store')

const people = [
  {
    id: 1,
    name: 'SOL',
    avatar: require('@/assets/logo/memegosol.png'),
  },
  {
    id: 2,
    name: 'ETH',
    avatar: require('@/assets/logo/memegoeth.png'),
  },
  {
    id: 3,
    name: 'BASE',
    avatar: require('@/assets/logo/memegobase.png'),
  },
  {
    id: 4,
    name: 'BSC',
    avatar: require('@/assets/logo/memegobsc.png'),
  }
]

const showModal = ref(false)

// 获取存储的选择值
const savedSelectedId = localStorage.getItem('selectedPersonId')
const initialSelected = savedSelectedId ? people.find(p => p.id === parseInt(savedSelectedId)) : people[0]

if (!store.selected) {
  store.selected = initialSelected; // 默认选择存储的或第一个
}

const selected = computed({
  get() {
    return store.selected
  },
  set(value) {
    store.setSelected(value)
  }
})

const selectNetwork = (network) => {
  selected.value = network
  localStorage.setItem('selectedPersonId', network.id)
  toggleModal()
}

const toggleModal = () => {
  showModal.value = !showModal.value
}

onMounted(() => {
  if (savedSelectedId && !store.selected) {
    store.selected = initialSelected;
  }
})
</script>

<style scoped>
.wallet-button {
  background: white;
  padding: 0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 14px 0 rgba(0, 118, 255, 0.39);
  width: 180px;
  height: 38px;
  overflow: hidden;
}

.wallet-button:hover {
  background: #f0f0f0;
}

.network-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.network-modal-content {
  background-color: white;
  padding: 24px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 400px;
  max-width: 90%;
}

.network-options {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 20px;
}

.network-option {
  display: flex;
  align-items: center;
  background-color: #f3f4f6;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 8px 0;
  text-align: left;
  height: 62px; /* 固定高度 */
}

.network-option:hover {
  background-color: #e5e7eb;
  transform: translateY(-2px);
}

.image-container {
  width: 180px;
  height: 38px;
  overflow: hidden;
  border-radius: 10px;
  flex-shrink: 0;
  margin-right: 16px;
}

.network-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.network-name {
  font-size: 16px;
  font-weight: 500;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close-modal-button {
  background-color: #ff6b6b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
}

.close-modal-button:hover {
  background-color: #ff5252;
}

.close-icon {
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s;
}

.close-icon:hover {
  color: #ff0000;
}
</style>