<template>
  <div>
    <div class="relative flex items-center">
<!--      <button @click="goToMyPosition" v-if="isConnected" class="link-button">查看我的仓位</button>-->
      <button @click="toggleLogoutConfirm" class="wallet-button">
        <span v-if="isConnected">{{ formattedAddress }} ({{ formattedBalance }} SOL)</span>
        <span v-else>请登录钱包</span>
      </button>
      <!-- Logout Confirmation Modal -->
      <div v-if="showLogoutConfirm" class="logout-confirm">
        <p>确认退出登录？</p>
        <button @click="disconnectWallet" class="confirm-button">确认</button>
        <button @click="cancelLogout" class="cancel-button"><strong>取消</strong></button>
      </div>
    </div>

    <div>
      <!-- Wallet Selection Modal -->
      <div v-if="showWalletModal" class="wallet-modal">
        <div class="wallet-modal-content relative">
          <button @click="closeWalletModal" class="close-icon absolute top-0 right-0 mt-2 mr-2">
            &times;
          </button>
          <h3><strong>选择钱包</strong></h3>
          <div class="wallet-options flex justify-between">
            <button @click="connectWallet('phantom')" class="wallet-option">
              <img :src="require('@/assets/logo/Phantom.png')" alt="Phantom" class="h-24 w-24" />
            </button>
            <button @click="connectWallet('backpack')" class="wallet-option">
              <img :src="require('@/assets/logo/backpack.png')" alt="Backpack" class="h-24 w-24" />
            </button>
            <button @click="connectWallet('okxwallet')" class="wallet-option">
              <img :src="require('@/assets/logo/OKX.png')" alt="OKX" class="h-24 w-24" />
            </button>
          </div>
          <button @click="closeWalletModal" class="close-modal-button mt-4">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { Connection, LAMPORTS_PER_SOL } from '@solana/web3.js';

const walletAddress = ref(null);
const balance = ref(0);
const isConnected = ref(false);
const showLogoutConfirm = ref(false);
const showWalletModal = ref(false);
const connection = new Connection('https://mainnet.helius-rpc.com/?api-key=56675e7d-eb04-4bcd-886d-4231c63cfb56');
const router = useRouter();

const formattedAddress = computed(() => {
  if (!walletAddress.value) return '';
  const address = walletAddress.value;
  return `${address.slice(0, 4)}...${address.slice(-4)}`;
});

const formattedBalance = computed(() => {
  return balance.value.toFixed(2);
});

const connectWallet = async (walletType) => {
  let wallet;
  if (walletType === 'backpack' && window.backpack) {
    wallet = window.backpack;
  } else if (walletType === 'phantom' && window.solana && window.solana.isPhantom) {
    wallet = window.solana;
  } else if (walletType === 'okxwallet' && window.okxwallet) {
    wallet = window.okxwallet;
  }

  if (wallet) {
    try {
      await wallet.connect();
      walletAddress.value = wallet.publicKey.toString();
      const balanceInLamports = await connection.getBalance(wallet.publicKey);
      balance.value = balanceInLamports / LAMPORTS_PER_SOL;
      isConnected.value = true;
      closeWalletModal();
    } catch (err) {
      console.error("Wallet connection failed:", err);
    }
  } else {
    alert("请安装对应的钱包应用。");
  }
};

const toggleLogoutConfirm = () => {
  if (isConnected.value) {
    showLogoutConfirm.value = !showLogoutConfirm.value;
  } else {
    toggleWalletModal();
  }
};

const closeWalletModal = () => {
  showWalletModal.value = false;
};

const toggleWalletModal = () => {
  showWalletModal.value = !showWalletModal.value;
};

const cancelLogout = () => {
  showLogoutConfirm.value = false;
};

const disconnectWallet = async () => {
  let wallet;
  if (window.backpack) {
    wallet = window.backpack;
  } else if (window.solana && window.solana.isPhantom) {
    wallet = window.solana;
  } else if (window.okxwallet) {
    wallet = window.okxwallet;
  }

  if (wallet) {
    try {
      await wallet.disconnect();
      walletAddress.value = null;
      balance.value = 0;
      isConnected.value = false;
      showLogoutConfirm.value = false;
    } catch (err) {
      console.error("Wallet disconnection failed:", err);
    }
  }
};

const checkWalletConnection = async () => {
  let wallet;
  if (window.backpack) {
    wallet = window.backpack;
  } else if (window.solana && window.solana.isPhantom) {
    wallet = window.solana;
  } else if (window.okxwallet) {
    wallet = window.okxwallet;
  }

  if (wallet && wallet.publicKey) {
    walletAddress.value = wallet.publicKey.toString();
    const balanceInLamports = await connection.getBalance(wallet.publicKey);
    balance.value = balanceInLamports / LAMPORTS_PER_SOL;
    isConnected.value = true;
  }
};

const goToMyPosition = () => {
  router.push({ path: '/myPosition/', query: { address: walletAddress.value } });
};

onMounted(() => {
  checkWalletConnection();
  window.addEventListener('walletConnected', checkWalletConnection);
});

onUnmounted(() => {
  window.removeEventListener('walletConnected', checkWalletConnection);
});

</script>

<style scoped>
.relative {
  display: flex;
  align-items: center;
}

.link-button {
  margin-right: 20px;
  color: #8e44ad;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.link-button:hover {
  color: #732d91;
}

.wallet-button {
  background: linear-gradient(90deg, #8e44ad 0%, #14F195 100%);
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 14px 0 rgba(0, 118, 255, 0.39);
}

.wallet-button:hover {
  background: linear-gradient(90deg, #732d91 0%, #13C896 100%);
}

.wallet-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.wallet-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet-options {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.wallet-option {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(240, 240, 240, 0.9);
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 0 10px;
}

.wallet-option:hover {
  background-color: rgba(224, 224, 224, 0.9);
  transform: scale(1.05);
}

.close-modal-button {
  background-color: #ff6b6b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.logout-confirm {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(10px);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 10;
  width: 100%;
}

.confirm-button {
  background-color: #8e44ad;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s;
}

.confirm-button:hover {
  background-color: #732d91;
}

.cancel-button {
  background-color: #14F195;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s;
}

.cancel-button:hover {
  background-color: #13C896;
}

.close-icon {
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s;
}

.close-icon:hover {
  color: #ff0000;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
</style>