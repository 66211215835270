<template>
  <div class="p-2">
    <div v-if="loading" class="text-center text-blue-500 font-bold">加载中...</div>
    <div v-if="!showTrades">
      <table class="min-w-full bg-white mt-2">
        <thead>
        <tr class="bg-blue-500 text-white">
          <th class="py-2 px-4 border-b border-gray-200 text-xs">KOL 名称</th>
          <th class="py-2 px-4 border-b border-gray-200 text-xs">持仓百分比</th>
          <th class="py-2 px-4 border-b border-gray-200 text-xs">持仓数量</th>
          <th class="py-2 px-4 border-b border-gray-200 text-xs">持仓价值</th>
          <th class="py-2 px-4 border-b border-gray-200 text-xs">交易详情</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(kol, index) in kolHoldings" :key="index" class="text-sm font-bold">
          <td class="py-2 px-4 border-b border-gray-200">{{ kol.name }}</td>
          <td class="py-2 px-4 border-b border-gray-200 text-blue-400 italic">{{ kol.holdingPercentage }}%</td>
          <td class="py-2 px-4 border-b border-gray-200">{{ formatQuantity(kol.holdingQuantity) }}</td>
          <td class="py-2 px-4 border-b border-gray-200 text-green-700">{{ kol.holdingValue }}</td>
          <td class="py-2 px-4 border-b border-gray-200 text-center">
            <i class="fas fa-filter text-blue-500 cursor-pointer" @click="showTradeDetails(kol.address)"></i> <!-- 漏斗图标 -->
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-else>
      <TradeDetails :address="selectedAddress" :tokenAddress="tokenAddress.address" :tokenSymbol="tokenAddress.symbol" @goBack="goBackToHolders" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { Connection, PublicKey } from '@solana/web3.js';
import { getMint } from '@solana/spl-token';
import TradeDetails from './TradeDetails.vue';
import dizhi from './dizhi.json';

const props = defineProps({
  poolAddress: {
    type: String,
    required: true,
  },
});

const kolHoldings = ref([]);
const loading = ref(true);
const showTrades = ref(false);
const selectedAddress = ref(null);
const tokenAddress = ref({ address: '', symbol: '' });

const connection = new Connection('https://mainnet.helius-rpc.com/?api-key=56675e7d-eb04-4bcd-886d-4231c63cfb56', 'confirmed');

const fetchAndDisplayTokenHolders = async (poolAddress) => {
  loading.value = true;
  kolHoldings.value = [];

  try {
    const { tokenMintAddress, symbol, priceUsd } = await getTokenMintAddressFromPool(poolAddress);
    tokenAddress.value = { address: tokenMintAddress, symbol };
    const tokenMintPublicKey = new PublicKey(tokenMintAddress);
    const mintInfo = await getMint(connection, tokenMintPublicKey);
    const totalSupply = Number(mintInfo.supply) / 10 ** mintInfo.decimals;

    const requestOptions = {
      method: 'get',
      headers: {
        'token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE3MjM3Njg4NTc1NTYsImVtYWlsIjoiamlhbmhhb2JpYW81MDJAZ21haWwuY29tIiwiYWN0aW9uIjoidG9rZW4tYXBpIiwiYXBpVmVyc2lvbiI6InYyIiwiaWF0IjoxNzIzNzY4ODU3fQ.p88squ6AxqhlfnCzlcDv0GylTCnQaCPkvpFfofNBDP0'
      }
    };

    const fetchPage = async (page) => {
      const url = `https://pro-api.solscan.io/v2.0/token/holders?address=${tokenMintAddress}&page=${page}&page_size=40`;
      const response = await axios.get(url, requestOptions);
      return response.data.data.items;
    };

    const holdersData = await Promise.all(
        Array.from({ length: 20 }, (_, i) => fetchPage(i + 1))
    );

    const allHolders = holdersData.flat();

    allHolders.forEach((holder) => {
      const owner = holder.owner;

      if (dizhi[owner]) {
        const amount = Math.floor(holder.amount / 10 ** holder.decimals);
        const holdingPercentage = ((amount / totalSupply) * 100).toFixed(2);

        const holdingValue = `$${(amount * priceUsd).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

        kolHoldings.value.push({
          address: owner,
          name: dizhi[owner],
          holdingPercentage,
          holdingQuantity: amount,
          holdingValue,
        });
      }
    });

  } catch (error) {
    console.error('Error fetching token holders:', error);
  } finally {
    loading.value = false;
  }
};

const getTokenMintAddressFromPool = async (poolAddress) => {
  const url = `https://api.dexscreener.com/latest/dex/pairs/solana/${poolAddress}`;
  try {
    const response = await axios.get(url);
    const baseTokenAddress = response.data.pairs[0].baseToken.address;
    const symbol = response.data.pairs[0].baseToken.symbol;
    const priceUsd = parseFloat(response.data.pairs[0].priceUsd);
    return { tokenMintAddress: baseTokenAddress, symbol, priceUsd };
  } catch (error) {
    console.error('Error fetching token mint address from pool:', error);
    throw error;
  }
};

const showTradeDetails = (address) => {
  selectedAddress.value = address;
  showTrades.value = true;
};

const goBackToHolders = () => {
  showTrades.value = false;
};

const formatQuantity = (quantity) => {
  if (quantity >= 1e9) return (quantity / 1e9).toFixed(2) + 'B';
  if (quantity >= 1e6) return (quantity / 1e6).toFixed(2) + 'M';
  if (quantity >= 1e3) return (quantity / 1e3).toFixed(2) + 'K';
  return quantity;
};

onMounted(() => {
  fetchAndDisplayTokenHolders(props.poolAddress);
});

watch(() => props.poolAddress, (newPoolAddress) => {
  showTrades.value = false; // 每次切换poolAddress时将showTrades设置为false
  fetchAndDisplayTokenHolders(newPoolAddress);
});
</script>

<style>
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
</style>