<script setup>
import { provide,ref, onMounted, onUpdated } from 'vue';
import feather from 'feather-icons';
import AppHeader from './components/shared/AppHeader';
import AppFooter from './components/shared/AppFooter';
import { store } from './store'


// Data properties
const appTheme = ref(localStorage.getItem('theme'));

// Lifecycle hooks
onMounted(() => {
  feather.replace();
});

onUpdated(() => {
  feather.replace();
});

provide('store', store)
</script>

<template>
  <div :class="appTheme" class="flex flex-col min-h-screen">
    <!-- App header -->
    <AppHeader />

    <!-- Main content area -->
    <main class="flex-grow">
      <!-- Render active component contents with vue transition -->
      <transition name="fade" mode="out-in">
        <router-view :theme="appTheme" />
      </transition>
    </main>

    <!-- Scroll to top -->
    <back-to-top
        visibleoffset="500"
        right="30px"
        bottom="20px"
        class="shadow-lg"
    >
      <i data-feather="chevron-up"></i>
    </back-to-top>

    <!-- App footer -->
    <AppFooter />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 0; /* Ensure no padding on the main container */
  margin: 0;  /* Ensure no margin on the main container */
}

.vue-back-to-top {
  @apply p-2 bg-indigo-500 hover:bg-indigo-600 text-white transition
  duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110;
  border-radius: 50%;
  font-size: 22px;
  line-height: 22px;
}

@keyframes going {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-10px);
    opacity: 0;
  }
}

@keyframes coming {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
</style>