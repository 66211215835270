<template>
  <div class="container mx-auto">
    <div class="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark">
      <div class="flex flex-col justify-center items-center">
        <p class="font-general-semibold text-3xl sm:text-4xl font-semibold text-primary-dark dark:text-primary-light mb-10">
          合作伙伴
        </p>
        <div class="flex flex-col sm:flex-row justify-center items-center gap-8 w-full">
          <a v-for="partner in partners" :key="partner.name" :href="partner.url" target="_blank"
             class="w-full sm:w-1/2 max-w-md flex flex-col items-center hover:opacity-80 transition-opacity">
            <img :src="partner.logo" :alt="partner.name" :class="partner.logoClass" />
          </a>
        </div>
      </div>
    </div>

    <!-- Footer social links -->
    <div class="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark">
      <div class="flex flex-col justify-center items-center mb-12 sm:mb-20">
        <p class="font-general-semibold text-3xl sm:text-4xl font-semibold text-primary-dark dark:text-primary-light mb-5">
          Follow me
        </p>
        <ul class="flex gap-4 sm:gap-8">
          <a
              v-for="social in socials"
              :key="social.id"
              :href="social.url"
              target="__blank"
              class="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-4 duration-500"
          >
            <i :data-feather="social.icon" class="w-5 sm:w-8 h-5 sm:h-8"></i>
          </a>
        </ul>
      </div>

      <!-- Footer copyright -->
      <FooterCopyright />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import FooterCopyright from './FooterCopyright.vue';
import { socialLinks } from '../../data/socialLinks';

const chainbaseLogo = new URL('@/assets/grant/chainbase.png', import.meta.url).href;
const tradingViewLogo = new URL('@/assets/grant/TradingView.png', import.meta.url).href;

const partners = ref([
  {
    name: 'TradingView',
    url: 'https://www.tradingview.com/',
    logo: tradingViewLogo,
    logoClass: 'h-32 sm:h-48 object-contain mb-4', // 增加了 TradingView logo 的大小
  },
  {
    name: 'Chainbase',
    url: 'https://chainbase.com/',
    logo: chainbaseLogo,
    logoClass: 'h-16 object-contain mb-4', // 保持原有大小
  }
]);

const socials = ref(socialLinks);

// Feather icons setup
import {onMounted, onUpdated} from 'vue';
import feather from 'feather-icons';

onMounted(() => {
  feather.replace();
});

onUpdated(() => {
  feather.replace();
});
</script>