<template>
  <div class="flex items-center space-x-2 bg-gradient-to-r from-purple-500 to-green-500 p-2 rounded-lg shadow-md">
    <!-- 切换开关 -->
    <label class="flex items-center cursor-pointer">
      <input type="checkbox" v-model="isQuickBuyEnabled" class="hidden">
      <div class="relative">
        <div
            class="w-10 h-6 bg-gray-300 rounded-full shadow-inner transition duration-300"
            :class="{'bg-blue-500': isQuickBuyEnabled}"
        ></div>
        <div
            class="dot absolute left-0 top-0 w-6 h-6 bg-white rounded-full shadow transition transform duration-300 ease-in-out"
            :class="{'translate-x-4 bg-blue-500': isQuickBuyEnabled}"
        ></div>
      </div>
      <span class="ml-3 text-white font-bold">Quick Buy</span>
    </label>
    <!-- 数量显示 -->
    <div class="flex items-center px-3 py-1 rounded-lg"
         :class="{'bg-gray-200': !isQuickBuyEnabled, 'bg-white': isQuickBuyEnabled}">
      <img src="https://cryptologos.cc/logos/solana-sol-logo.png?v=014" alt="SOL" class="w-4 h-4">
      <input
          type="number"
          v-model="amount"
          :disabled="!isQuickBuyEnabled"
          class="ml-1 w-16 bg-transparent border-none focus:outline-none appearance-none h-6"
          :class="{'text-gray-700': !isQuickBuyEnabled, 'text-gray-900': isQuickBuyEnabled, 'bg-white': isQuickBuyEnabled}"
          @input="updateAmount"
      />
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue';

const isQuickBuyEnabled = ref(false);
const amount = ref(1);
const emit = defineEmits(['update:amount']);

// Load amount from localStorage on component mount
onMounted(() => {
  const savedAmount = localStorage.getItem('newQuickBuyAmount');
  if (savedAmount !== null) {
    amount.value = parseFloat(savedAmount);
  }
});

// Emit event to parent component when amount changes
const updateAmount = () => {
  emit('update:amount', amount.value);
  localStorage.setItem('newQuickBuyAmount', amount.value);
};

watch(amount, (newAmount) => {
  emit('update:amount', newAmount);
  localStorage.setItem('newQuickBuyAmount', newAmount);
});
</script>

<style>
.dot {
  top: 0.25rem;
  left: 0.25rem;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>