<template>
  <div class="mt-2 flex flex-col font-heiti">
    <div class="flex items-center mb-1 mt-4">
      <div class="text-2xl font-semibold mr-4">趋势</div>
      <BaseQuickBuy @update:amount="handleUpdateAmount"></BaseQuickBuy>
    </div>
    <div class="mb-2"></div>

    <hr class="border-gray-300 w-full">

    <div class="overflow-x-auto flex-1" style="max-height: 800px;">
      <div class="inline-block min-w-full py-2 align-middle">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
          <tr>
            <th v-for="header in tableHeaders" :key="header.field" scope="col" class="pt-0.5 pb-1 pl-2 pr-3 text-left text-sm font-black text-gray-500 sm:pl-0">
              {{ header.label }}
              <button v-if="header.sortable" @click="toggleSort(header.field)" class="ml-1 focus:outline-none">
                <svg v-if="sortConfig.field !== header.field" class="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"></path>
                </svg>
                <svg v-else-if="sortConfig.direction === 'asc'" class="w-4 h-4 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path>
                </svg>
                <svg v-else class="w-4 h-4 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>
            </th>
          </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
          <template v-if="isLoading">
            <tr v-for="n in 10" :key="n" class="animate-pulse">
              <td v-for="header in tableHeaders" :key="header.field" class="whitespace-nowrap px-3 py-4 text-sm">
                <div class="h-4 bg-slate-200 rounded" :class="{'w-20': header.field === 'name', 'w-16': header.field !== 'name'}"></div>
              </td>
            </tr>
          </template>
          <template v-else>
            <BASENewPoolsTableRow
                v-for="(pool, index) in sortedPools"
                :key="pool.id || pool.poolAddress"
                :pool="pool"
                :index="index"
                :showDetailsIndex="showDetailsIndex"
                :quickBuyAmount="quickBuyAmount"
                @showCommunityDetails="showCommunityDetails"
                @checkSmartMoney="checkSmartMoney"
                @checkSimilarTokens="checkSimilarTokens"
                @handleMouseOver="handleMouseOver"
                @handleMouseLeave="handleMouseLeave"
                @navigateTo="navigateTo"
                @copyToClipboard="copyToClipboard"
                @buyToken="buyToken"
            />
          </template>
          </tbody>
        </table>
      </div>
    </div>

    <SlideOver :open="isSlideOverOpen" @close="toggleSlideOver">
      <BASECalCommunityIncludeV2 :poolAddress="currentPoolAddress"></BASECalCommunityIncludeV2>
    </SlideOver>

    <SlideOver :open="isSmartMoneyCheckOpen" @close="toggleSmartMoneySlideOver">
      <BASEUserAnalysisV2 :poolAddress="currentPoolAddress"></BASEUserAnalysisV2>
    </SlideOver>

    <SlideOver :open="isToolsBoxOpen" @close="toggleToolsBoxSlideOver">
      <ToolsBox :poolAddress="currentPoolAddress"></ToolsBox>
    </SlideOver>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, toRefs, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import SlideOver from "@/components/projects/SlideOver.vue";
import ToolsBox from "@/views/trans/ToolsBox.vue";
import BaseQuickBuy from "@/components/projects/BaseQuickBuy.vue";
import BASENewPoolsTableRow from "@/views/BASENewPoolsTableRow.vue";
import BASECalCommunityIncludeV2 from "@/components/projects/BASECalCommunityIncludeV2.vue";
import BASEUserAnalysisV2 from "@/views/trans/BASEUserAnalysisV2.vue";

const isSlideOverOpen = ref(false);
const isSmartMoneyCheckOpen = ref(false);
const isToolsBoxOpen = ref(false);
const currentPoolAddress = ref('');
const quickBuyAmount = ref(1);
const poolDataArray = ref([]);
const filteredPools = ref([]);
const showDetailsIndex = ref(null);
const router = useRouter();
const isLoading = ref(true);

const props = defineProps({
  filters: Object
});

const { filters } = toRefs(props);

const sortConfig = ref({
  field: 'default',
  direction: 'desc'
});

const tableHeaders = [
  { field: 'name', label: '币种', sortable: false },
  { field: 'quickBuy', label: '快速购买', sortable: false },
  { field: 'price', label: '价格', sortable: false },
  { field: 'createdTime', label: '创建', sortable: true },
  { field: 'security', label: '安全分析', sortable: false },
  { field: 'poolSize', label: '池子', sortable: true },
  { field: 'fdv', label: 'FDV', sortable: true },
  { field: 'tradeCount24h', label: '24h交易', sortable: true },
  { field: 'volume24h', label: '24h量', sortable: true },
  { field: 'priceChange5m', label: '5m', sortable: true },
  { field: 'priceChange1h', label: '1h', sortable: true },
  { field: 'priceChange6h', label: '6h', sortable: true },
  { field: 'priceChange24h', label: '24h', sortable: true }
];

const toggleSort = (field) => {
  if (sortConfig.value.field === field) {
    sortConfig.value.direction = sortConfig.value.direction === 'asc' ? 'desc' : 'asc';
  } else {
    sortConfig.value.field = field;
    sortConfig.value.direction = 'desc';
  }
};

const sortedPools = computed(() => {
  if (sortConfig.value.field === 'default') {
    return filteredPools.value;
  }

  return [...filteredPools.value].sort((a, b) => {
    let aValue, bValue;
    switch (sortConfig.value.field) {
      case 'createdTime':
        aValue = parseInt(a.poolCreatedTime.replace(/[mhd]/, ''));
        bValue = parseInt(b.poolCreatedTime.replace(/[mhd]/, ''));
        break;
      case 'poolSize':
        aValue = parseFloat(a.reserve.replace(/[KMB]/, ''));
        bValue = parseFloat(b.reserve.replace(/[KMB]/, ''));
        break;
      case 'fdv':
        aValue = parseFloat(a.fdv_usd.replace(/[$,]/g, ''));
        bValue = parseFloat(b.fdv_usd.replace(/[$,]/g, ''));
        break;
      case 'tradeCount24h':
        aValue = parseInt(a.buys24h);
        bValue = parseInt(b.buys24h);
        break;
      case 'volume24h':
        aValue = parseFloat(a.volume24h.replace(/[$,]/g, ''));
        bValue = parseFloat(b.volume24h.replace(/[$,]/g, ''));
        break;
      case 'priceChange5m':
      case 'priceChange1h':
      case 'priceChange6h':
      case 'priceChange24h':
        aValue = parseFloat(a[sortConfig.value.field].replace('%', ''));
        bValue = parseFloat(b[sortConfig.value.field].replace('%', ''));
        break;
      default:
        aValue = a[sortConfig.value.field];
        bValue = b[sortConfig.value.field];
    }
    return sortConfig.value.direction === 'asc' ? aValue - bValue : bValue - aValue;
  });
});

const toggleSlideOver = () => {
  isSlideOverOpen.value = !isSlideOverOpen.value;
};

const toggleSmartMoneySlideOver = () => {
  isSmartMoneyCheckOpen.value = !isSmartMoneyCheckOpen.value;
};

const toggleToolsBoxSlideOver = () => {
  isToolsBoxOpen.value = !isToolsBoxOpen.value;
};

const showCommunityDetails = (pool) => {
  currentPoolAddress.value = pool.poolAddress;
  toggleSlideOver();
};

const checkSmartMoney = (pool) => {
  currentPoolAddress.value = pool.poolAddress;
  toggleSmartMoneySlideOver();
};

const checkSimilarTokens = (pool) => {
  currentPoolAddress.value = pool.poolAddress;
  toggleToolsBoxSlideOver();
};

const handleUpdateAmount = (amount) => {
  quickBuyAmount.value = amount;
};

const copyToClipboard = (pool) => {
  navigator.clipboard.writeText(pool.baseTokenAddress).then(() => {
    pool.copied = true;
    setTimeout(() => {
      pool.copied = false;
    }, 2000);
  });
};

const navigateTo = (pool) => {
  router.push({ path: `/base_lp/${pool.poolAddress}` });
};

const buyToken = (token) => {
  alert(`快速购买 ${token.name}`);
};

const handleMouseOver = (index) => {
  showDetailsIndex.value = index;
};

const handleMouseLeave = () => {
  showDetailsIndex.value = null;
};

const fetchPoolsData = async () => {
  try {
    isLoading.value = true;
    const response = await axios.get('https://api.memego.ai/api/base/newPools');
    poolDataArray.value = response.data.poolsData;
    filterPools();
    fetchSecurityInfo();
  } catch (error) {
    console.error('Error fetching the data:', error);
  } finally {
    isLoading.value = false;
  }
};

const fetchSecurityInfo = async () => {
  try {
    const tokens = poolDataArray.value.map(pool => pool.baseTokenAddress).join(',');
    const response = await axios.get(`https://api.memego.ai/api/tokenSecurity?tokens=${tokens}&chain=8453`);

    const securityInfo = response.data.securityInfo;

    poolDataArray.value = poolDataArray.value.map(pool => ({
      ...pool,
      security: securityInfo[pool.baseTokenAddress.toLowerCase()]
    }));

    filterPools();
  } catch (error) {
    console.error('Error fetching security info:', error);
  }
};

const filterPools = () => {
  let filtered = poolDataArray.value;
  const poolSizeFilter = filters.value.poolSize;
  const poolSizeSelected = filters.value.poolSizeSelected;
  const createTimeSelected = filters.value.createTimeSelected;
  const creationTimeFilter = filters.value.creationTime;

  if (poolSizeFilter && poolSizeSelected) {
    const sizeThresholds = {
      'poolSize1K': 1000,
      'poolSize5K': 5000,
      'poolSize10K': 10000,
      'poolSize100K': 100000
    };

    filtered = filtered.filter(pool => {
      const reserveValue = parseFloat(pool.reserve.replace(/[KMB]/, ''));
      const unit = pool.reserve.slice(-1);
      let reserveInUSD;

      if (unit === 'K') {
        reserveInUSD = reserveValue * 1000;
      } else if (unit === 'M') {
        reserveInUSD = reserveValue * 1000000;
      } else if (unit === 'B') {
        reserveInUSD = reserveValue * 1000000000;
      } else {
        reserveInUSD = reserveValue;
      }
      return reserveInUSD > sizeThresholds[poolSizeFilter];
    });
  }

  if (creationTimeFilter && createTimeSelected) {
    const timeThresholds = {
      'recent5m': 5,
      'recent10m': 10,
      'recent30m': 30,
      'recent100m': 100
    };

    const thresholdMinutes = timeThresholds[creationTimeFilter];

    filtered = filtered.filter(pool => {
      const createdTime = pool.poolCreatedTime;
      let createdMinutesAgo;

      if (typeof createdTime === 'string') {
        const value = parseInt(createdTime);
        const unit = createdTime.slice(-1).toLowerCase();

        switch (unit) {
          case 'm':
            createdMinutesAgo = value;
            break;
          case 'h':
            createdMinutesAgo = value * 60;
            break;
          case 'd':
            createdMinutesAgo = value * 24 * 60;
            break;
          default:
            return false;
        }
      } else if (typeof createdTime === 'number') {
        createdMinutesAgo = createdTime; } else {
        return false;
      }

      return createdMinutesAgo <= thresholdMinutes;
    });
  }

  filteredPools.value = filtered;
};

watch(filters, filterPools, { deep: true });

onMounted(() => {
  fetchPoolsData();
  setInterval(fetchPoolsData, 60000); // 每分钟更新一次数据
});
</script>

<style scoped>
.font-heiti {
  font-family: 'HeiTi', sans-serif;
}
</style>