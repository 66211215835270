<template>
  <div>
    <div class="relative flex items-center">
<!--      <button @click="goToMyPosition" v-if="isConnected" class="link-button">查看我的仓位</button>-->
      <button @click="handleWalletClick" class="wallet-button bg-coinbase-blue">
        <span v-if="isConnected">{{ formattedAddress }} ({{ formattedBalance }} ETH)</span>
        <span v-else>请登录钱包</span>
      </button>
      <!-- Logout Confirmation Modal -->
      <div v-if="showLogoutConfirm" class="logout-confirm">
        <p>确认退出登录？</p>
        <button @click="disconnectWallet" class="confirm-button">确认</button>
        <button @click="cancelLogout" class="cancel-button"><strong>取消</strong></button>
      </div>
    </div>

    <div>
      <!-- Wallet Selection Modal -->
      <div v-if="showWalletModal" class="wallet-modal">
        <div class="wallet-modal-content relative">
          <button @click="closeWalletModal" class="close-icon absolute top-0 right-0 mt-2 mr-2">
            &times;
          </button>
          <h3><strong>选择钱包</strong></h3>
          <div class="wallet-options flex justify-between">
            <button @click="connectWallet('metamask')" class="wallet-option">
              <img :src="require('@/assets/logo/metamask.png')" alt="MetaMask" class="h-24 w-24" />
            </button>
            <button @click="connectWallet('okxwallet')" class="wallet-option">
              <img :src="require('@/assets/logo/OKX.png')" alt="OKX" class="h-24 w-24" />
            </button>
          </div>
          <button @click="closeWalletModal" class="close-modal-button mt-4">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import Web3 from 'web3';

const walletAddress = ref(null);
const balance = ref(0);
const isConnected = ref(false);
const showLogoutConfirm = ref(false);
const showWalletModal = ref(false);
const web3 = ref(null);
const router = useRouter();

const BASE_CHAIN_ID = '0x2105'; // BASE 网络的 Chain ID
const BASE_RPC_URL = 'https://mainnet.base.org'; // BASE 网络的 RPC URL

const formattedAddress = computed(() => {
  if (!walletAddress.value) return '';
  const address = walletAddress.value;
  return `${address.slice(0, 4)}...${address.slice(-4)}`;
});

const formattedBalance = computed(() => {
  return parseFloat(balance.value).toFixed(4);
});

const handleWalletClick = () => {
  if (isConnected.value) {
    showLogoutConfirm.value = true;
  } else {
    toggleWalletModal();
  }
};

const connectWallet = async (walletType) => {
  let provider;
  if (walletType === 'metamask' && window.ethereum) {
    provider = window.ethereum;
  } else if (walletType === 'okxwallet' && window.okxwallet) {
    provider = window.okxwallet;
  }

  if (provider) {
    try {
      await provider.request({ method: 'eth_requestAccounts' });
      web3.value = new Web3(provider);
      const accounts = await web3.value.eth.getAccounts();
      walletAddress.value = accounts[0];

      // 检查并切换到 BASE 网络
      await switchToBaseNetwork();

      // 获取 BASE 余额
      await updateBalance();

      isConnected.value = true;
      closeWalletModal();
    } catch (err) {
      console.error("Wallet connection failed:", err);
      alert("连接钱包失败，请确保您已安装并解锁钱包。");
    }
  } else {
    alert("请安装对应的钱包应用。");
  }
};

const switchToBaseNetwork = async () => {
  if (!web3.value || !web3.value.eth) return;

  try {
    await web3.value.eth.getChainId();
  } catch (error) {
    console.error("无法获取当前链 ID:", error);
    return;
  }

  const currentChainId = await web3.value.eth.getChainId();
  if (currentChainId !== parseInt(BASE_CHAIN_ID, 16)) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: BASE_CHAIN_ID }],
      });
    } catch (switchError) {
      // 如果 BASE 网络未添加到钱包，我们尝试添加它
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: BASE_CHAIN_ID,
                chainName: 'BASE',
                nativeCurrency: {
                  name: 'BASE',
                  symbol: 'BASE',
                  decimals: 18
                },
                rpcUrls: [BASE_RPC_URL],
                blockExplorerUrls: ['https://basescan.org/'],
              },
            ],
          });
        } catch (addError) {
          console.error('无法添加 BASE 网络:', addError);
          alert('无法添加 BASE 网络，请手动在钱包中添加。');
        }
      } else {
        console.error('无法切换到 BASE 网络:', switchError);
        alert('无法切换到 BASE 网络，请检查您的钱包设置。');
      }
    }
  }
};

const updateBalance = async () => {
  if (web3.value && walletAddress.value) {
    try {
      const balanceInWei = await web3.value.eth.getBalance(walletAddress.value);
      balance.value = web3.value.utils.fromWei(balanceInWei, 'ether');
    } catch (error) {
      console.error('获取余额失败:', error);
      balance.value = '0';
    }
  }
};

const closeWalletModal = () => {
  showWalletModal.value = false;
};

const toggleWalletModal = () => {
  showWalletModal.value = !showWalletModal.value;
};

const cancelLogout = () => {
  showLogoutConfirm.value = false;
};

const disconnectWallet = async () => {
  walletAddress.value = null;
  balance.value = 0;
  isConnected.value = false;
  showLogoutConfirm.value = false;
  web3.value = null;
};

const checkWalletConnection = async () => {
  let provider;
  if (window.ethereum) {
    provider = window.ethereum;
  } else if (window.okxwallet) {
    provider = window.okxwallet;
  }

  if (provider) {
    web3.value = new Web3(provider);
    try {
      const accounts = await web3.value.eth.getAccounts();
      if (accounts.length > 0) {
        walletAddress.value = accounts[0];
        await switchToBaseNetwork();
        await updateBalance();
        isConnected.value = true;
      }
    } catch (error) {
      console.error('检查钱包连接时出错:', error);
    }
  }
};

const goToMyPosition = () => {
  router.push({ path: '/myPosition/', query: { address: walletAddress.value } });
};

onMounted(() => {
  checkWalletConnection();
  window.addEventListener('ethereum#initialized', checkWalletConnection, {
    once: true,
  });
  setTimeout(checkWalletConnection, 3000);
});

onUnmounted(() => {
  window.removeEventListener('ethereum#initialized', checkWalletConnection);
});

</script>

<style scoped>
.relative {
  position: relative;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.link-button {
  margin-right: 20px;
  color: #8e44ad;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.link-button:hover {
  color: #732d91;
}

.wallet-button {
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.39);
}

.bg-coinbase-blue {
  background-color: #0052FF; /* 使用Coinbase的蓝色 */
}

.wallet-button:hover {
  background-color: #0039B3; /* 稍微深一点的蓝色作为hover状态 */
}

.wallet-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.wallet-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet-options {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.wallet-option {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(240, 240, 240, 0.9);
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 0 10px;
}

.wallet-option:hover {
  background-color: rgba(224, 224, 224, 0.9);
  transform: scale(1.05);
}

.close-modal-button {
  background-color: #ff6b6b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.logout-confirm {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(10px);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 10;
  width: 100%;
}

.confirm-button {
  background-color: #8e44ad;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s;
}

.confirm-button:hover {
  background-color: #732d91;
}

.cancel-button {
  background-color: #14F195;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s;
}

.cancel-button:hover {
  background-color: #13C896;
}

.close-icon {
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s;
}

.close-icon:hover {
  color: #ff0000;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.h-24 {
  height: 6rem;
}

.w-24 {
  width: 6rem;
}
</style>