<template>
  <div class="h-[75vh] bg-gray-100 rounded-lg shadow-lg overflow-hidden flex flex-col">
    <!-- 固定在顶部的标题栏 -->
    <div class="bg-white shadow-md p-4">
      <div class="flex items-center space-x-4">
        <img
            v-if="tokenAddress.address"
            :src="`https://dd.dexscreener.com/ds-data/tokens/bsc/${tokenAddress.address}.png`"
            :alt="tokenAddress.symbol"
            class="w-12 h-12 rounded-full"
        >
        <div>
          <h1 class="text-2xl font-bold text-gray-800">{{ tokenAddress.symbol || '加载中...' }}</h1>
          <p class="text-sm text-gray-500">社区持仓分析</p>
        </div>
      </div>
      <p v-if="tokenAddress.address" class="mt-2 text-sm text-gray-600">
        <span class="font-mono bg-gray-200 p-1 rounded">{{ tokenAddress.address }}</span>
      </p>
      <div v-if="tokenSecurity" class="mt-2 text-sm text-gray-600">
        <span class="mr-4">总供应量: {{ formatAmount(tokenSecurity.total_supply) }}</span>
        <span>持有人数: {{ tokenSecurity.holder_count.toLocaleString() }}</span>
      </div>
    </div>

    <!-- 内容区域 - 可滚动 -->
    <div class="flex-grow overflow-hidden flex flex-col">
      <div v-if="loading" class="flex-grow flex items-center justify-center">
        <div class="text-center">
          <div class="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
          <p class="mt-2 text-blue-500 font-semibold">加载中...</p>
        </div>
      </div>

      <div v-else class="flex-grow overflow-hidden flex flex-col">
        <!-- 标题栏 -->
        <div class="bg-gray-200 p-3 flex items-center font-bold">
          <div class="w-1/4 text-left text-gray-600">社区</div>
          <div class="w-1/6 text-gray-600">优质成员</div>
          <div class="w-1/6 text-gray-600">持仓数量</div>
          <div class="w-1/6 text-gray-600">持仓价值</div>
          <div class="w-1/4 text-gray-600 flex justify-end items-center pr-8">
            <span>持仓比例</span>
            <span></span> <!-- 此空span有助于对齐chevron图标 -->
          </div>
        </div>

        <!-- 社区列表 - 可滚动 -->
        <div class="flex-grow overflow-y-auto">
          <div class="space-y-2 p-2">
            <div
                v-for="(community, index) in selectedPoolCommunityContent"
                :key="index"
                class="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer"
                @click="toggleDetails(index)"
            >
              <div class="p-3 flex items-center">
                <!-- 头像和名称 -->
                <div class="flex items-center space-x-4 w-1/4">
                  <img
                      :src="ETHtokenAvatars[community.name] || 'default-avatar.png'"
                      :alt="community.name"
                      class="w-10 h-10 rounded-full border-2 border-gray-300"
                  />
                  <p class="text-lg font-semibold text-gray-900">{{ community.name }}</p>
                </div>

                <!-- 成员数量 -->
                <div class="w-1/6">
                  <p class="text-sm text-blue-600 font-medium">{{ community.addresses.length }}</p>
                </div>

                <!-- 持仓数量 -->
                <div class="w-1/6">
                  <p class="text-sm text-blue-600 font-medium">{{ community.totalAmount }}</p>
                </div>

                <!-- 持仓价值 -->
                <div class="w-1/6">
                  <p class="text-sm text-green-700 font-medium">${{ community.totalHoldingValue }}</p>
                </div>

                <!-- 持仓比例 -->
                <div class="w-1/4 flex items-center justify-end space-x-4 pr-8">
                  <p class="text-sm text-purple-600 font-medium">{{ community.percentage }}%</p>
                  <i :class="['fas', expandedIndex === index ? 'fa-chevron-up' : 'fa-chevron-down', 'text-blue-500']"></i>
                </div>
              </div>

              <!-- 详细信息 -->
              <div v-if="expandedIndex === index" class="px-3 pb-3">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">地址</th>
                    <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">持仓量</th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="(address, addressIndex) in community.addresses" :key="addressIndex">
                    <td class="px-3 py-2 text-left whitespace-nowrap text-sm font-medium text-gray-900">{{ address }}</td>
                    <td class="px-3 py-2 text-left whitespace-nowrap text-sm text-gray-500">{{ formatAmount(community.holdings[addressIndex]) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import axios from 'axios';
import ETHYingliDizhi from './ETHYingliDizhi.json';
import ETHtokenAvatars from './ETHtokenAvatars.json';
const { GoPlus, ErrorCode } = require("@goplus/sdk-node");

const props = defineProps({
  poolAddress: {
    type: String,
    required: true,
  },
});

const selectedPoolCommunityContent = ref([]);
const loading = ref(false);
const tokenAddress = ref({ address: '', symbol: '' });
const tokenSecurity = ref(null);
const expandedIndex = ref(null);

const formatAmount = (amount) => {
  if (amount >= 1e9) return (amount / 1e9).toFixed(2) + 'B';
  if (amount >= 1e6) return (amount / 1e6).toFixed(2) + 'M';
  if (amount >= 1e3) return (amount / 1e3).toFixed(2) + 'K';
  return amount.toFixed(2);
};

const toggleDetails = (index) => {
  expandedIndex.value = expandedIndex.value === index ? null : index;
};

const getTokenAddressFromPool = async (poolAddress) => {
  const url = `https://api.dexscreener.com/latest/dex/pairs/bsc/${poolAddress}`;
  try {
    const response = await axios.get(url);
    const baseTokenAddress = response.data.pairs[0].baseToken.address;
    const symbol = response.data.pairs[0].baseToken.symbol;
    const priceUsd = parseFloat(response.data.pairs[0].priceUsd);
    return { tokenAddress: baseTokenAddress, symbol, priceUsd };
  } catch (error) {
    console.error('Error fetching token address from pool:', error);
    throw error;
  }
};

const fetchTokenSecurity = async (token) => {
  let chainId = "56";
  let addresses = [token].map(address => address.toLowerCase());
  try {
    let res = await GoPlus.tokenSecurity(chainId, addresses, 30);
    if (res.code !== ErrorCode.SUCCESS) {
      console.error(res.message);
      return null;
    } else {
      const result = res.result[addresses[0]];
      return {
        holder_count: parseInt(result.holder_count),
        total_supply: parseFloat(result.total_supply),
      };
    }
  } catch (error) {
    console.error('Error fetching token security:', error);
    return null;
  }
};

const fetchTokenHolders = async (tokenAddress) => {
  const options = {
    method: 'GET',
    url: 'https://api.chainbase.online/v1/token/top-holders',
    headers: {
      'x-api-key': '2VOKejecXTzPgIgBNRPjv3q9bRf'
    },
    params: {
      chain_id: 56,
      contract_address: tokenAddress,
      limit: 100
    }
  };

  const allData = [];
  for (let page = 1; page <= 5; page++) {
    const response = await axios({ ...options, params: { ...options.params, page } });
    allData.push(...response.data.data);
  }

  return allData;
};

const fetchAndDisplayTokenHolders = async (poolAddress) => {
  loading.value = true;
  selectedPoolCommunityContent.value = [];

  try {
    const {tokenAddress: address, symbol, priceUsd} = await getTokenAddressFromPool(poolAddress);
    tokenAddress.value = {address, symbol};

    const securityInfo = await fetchTokenSecurity(address);
    if (securityInfo) {
      tokenSecurity.value = securityInfo;
    }

    const holders = await fetchTokenHolders(address);
    const totalSupply = tokenSecurity.value ? tokenSecurity.value.total_supply : holders.reduce((sum, holder) => sum + parseFloat(holder.amount), 0);

    const communityHoldings = {};

    holders.forEach((holder) => {
      const holderAddress = holder.wallet_address;
      const communities = ETHYingliDizhi[holderAddress] || [];

      if (communities.length > 0 && !communities.includes(symbol)) {
        const amount = parseFloat(holder.amount);
        const holdingPercentage = ((amount / totalSupply) * 100).toFixed(2);
        const holdingValue = (amount * priceUsd).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

        communities.forEach(community => {
          if (!communityHoldings[community]) {
            communityHoldings[community] = {
              name: community,
              addresses: [],
              holdings: [],
              totalAmount: 0,
              totalHoldingValue: 0,
              percentage: 0,
            };
          }

          communityHoldings[community].addresses.push(holderAddress);
          communityHoldings[community].holdings.push(amount);
          communityHoldings[community].totalAmount += amount;
          communityHoldings[community].totalHoldingValue += amount * priceUsd;
          communityHoldings[community].percentage += parseFloat(holdingPercentage);
        });
      }
    });

    selectedPoolCommunityContent.value = Object.values(communityHoldings).map(community => ({
      ...community,
      totalAmount: formatAmount(community.totalAmount),
      totalHoldingValue: community.totalHoldingValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      percentage: (community.percentage).toFixed(2),
    }));

  } catch (error) {
    console.error('Error fetching token holders:', error);
  } finally {
    loading.value = false;
  }
};

// Call the function to fetch and display data
fetchAndDisplayTokenHolders(props.poolAddress);

watch(() => props.poolAddress, (newAddress) => {
  if (newAddress && !loading.value) {
    fetchAndDisplayTokenHolders(newAddress);
  }
});
</script>