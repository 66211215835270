<template>
  <div class="mt-2 flex flex-col font-heiti">
    <div class="flex items-center mb-1 mt-4">
      <QuickBuy @update:amount="handleUpdateAmount"></QuickBuy>
    </div>
    <div class="mb-2"></div>

    <hr class="border-gray-300 w-full">

    <MainPoolTable
        :filteredPools="sortedPools"
        :showDetailsIndex="showDetailsIndex"
        :quickBuyAmount="quickBuyAmount"
        :placeholderRows="placeholderRows"
        :sortConfig="sortConfig"
        @showCommunityDetails="showCommunityDetails"
        @showSmartMoneyDetails="showSmartMoneyDetails"
        @showToolsBoxDetail="showToolsBoxDetail"
        @handleMouseOver="handleMouseOver"
        @handleMouseLeave="handleMouseLeave"
        @navigateTo="navigateTo"
        @copyToClipboard="copyToClipboard"
        @buyToken="buyToken"
        @toggleSort="toggleSort"
    ></MainPoolTable>

    <SlideOver :open="isSlideOverOpen" @close="toggleSlideOver">
      <CalCommunityInclude :poolAddress="currentPoolAddress"></CalCommunityInclude>
    </SlideOver>

    <SlideOver :open="isSmartMoneyCheckOpen" @close="toggleSmartMoneySlideOver">
      <UserAnalysis :poolAddress="currentPoolAddress"></UserAnalysis>
    </SlideOver>

    <SlideOver :open="isToolsBoxOpen" @close="toggleToolsBoxSlideOver">
      <ToolsBox :poolAddress="currentPoolAddress"></ToolsBox>
    </SlideOver>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, toRefs, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import QuickBuy from "@/components/projects/QuickBuy.vue";
import SlideOver from "@/components/projects/SlideOver.vue";
import CalCommunityInclude from "@/components/projects/CalCommunityInclude.vue";
import UserAnalysis from "@/views/trans/UserAnalysis.vue";
import ToolsBox from "@/views/trans/ToolsBox.vue";
import MainPoolTable from './MainPoolTable.vue';
import Tag from './Tag.json';

const isSlideOverOpen = ref(false);
const isSmartMoneyCheckOpen = ref(false);
const isToolsBoxOpen = ref(false);
const quickBuyAmount = ref(1);
const currentPoolAddress = ref('');
const poolDataArray = ref([]);
const filteredPools = ref([]);
const placeholderRows = ref(10);
const showDetailsIndex = ref(null);
const router = useRouter();
const sortConfig = ref({
  field: 'default',
  direction: 'desc'
});

const props = defineProps({
  filters: Object
});

const { filters } = toRefs(props);

const baseUrl = 'https://api.memego.ai/api/sol/trendingPools';

const getPriceChangeColor = (value) => {
  if (value === 'N/A') return 'gray';
  return parseFloat(value) >= 0 ? 'green' : 'red';
};

const parseNumericValue = (value) => {
  if (typeof value === 'string') {
    value = value.replace('$', '').replace(',', '');
    const multiplier = value.endsWith('K') ? 1000 : value.endsWith('M') ? 1000000 : value.endsWith('B') ? 1000000000 : 1;
    return parseFloat(value) * multiplier;
  }
  return value;
};

const fetchPoolsData = async () => {
  try {
    poolDataArray.value = [];
    const response = await axios.get(baseUrl);
    const pools = response.data.poolsData || [];

    pools.forEach((pool) => {
      poolDataArray.value.push({
        ...pool,
        tagValue: Tag[pool.baseTokenAddress] || "",
        priceChange5mColor: getPriceChangeColor(pool.priceChange5m),
        priceChange1hColor: getPriceChangeColor(pool.priceChange1h),
        priceChange6hColor: getPriceChangeColor(pool.priceChange6h),
        priceChange24hColor: getPriceChangeColor(pool.priceChange24h),
      });
    });

    placeholderRows.value = 0;
    filterPools();
  } catch (error) {
    console.error('Error fetching the data:', error);
  }
};

const filterPools = () => {
  let filtered = poolDataArray.value;
  const poolSizeFilter = filters.value.poolSize;
  const createTimeSelected = filters.value.createTimeSelected;
  const creationTimeFilter = filters.value.creationTime;

  if (poolSizeFilter) {
    const sizeThresholds = {
      'poolSize100K': 100_000,
      'poolSize500K': 500_000,
      'poolSize1M': 1_000_000,
      'poolSize10': 10_000_000,
    };

    filtered = filtered.filter(pool => {
      const reserveValue = parseNumericValue(pool.reserve);
      return reserveValue > sizeThresholds[poolSizeFilter];
    });
  }

  if (creationTimeFilter && createTimeSelected) {
    const timeThresholds = {
      'recent1Day': 1,
      'recent5Days': 5,
      'recent10Days': 10,
      'recent100Days': 100
    };

    const threshold = timeThresholds[creationTimeFilter];
    filtered = filtered.filter(pool => {
      const daysAgo = parseInt(pool.poolCreatedTime.replace('d', ''), 10);
      return daysAgo <= threshold;
    });
  }

  filteredPools.value = filtered;
};

const sortedPools = computed(() => {
  if (sortConfig.value.field === 'default') {
    return filteredPools.value;
  }

  return [...filteredPools.value].sort((a, b) => {
    let aValue, bValue;
    switch (sortConfig.value.field) {
      case 'solNum':
        aValue = parseFloat(a.solNum);
        bValue = parseFloat(b.solNum);
        break;
      case 'createdTime':
        aValue = parseInt(a.poolCreatedTime.replace('d', ''));
        bValue = parseInt(b.poolCreatedTime.replace('d', ''));
        break;
      case 'volume24h':
        aValue = parseNumericValue(a.volume24h);
        bValue = parseNumericValue(b.volume24h);
        break;
      case 'fdv_usd':
        aValue = parseNumericValue(a.fdv_usd);
        bValue = parseNumericValue(b.fdv_usd);
        break;
      case 'priceChange5m':
      case 'priceChange1h':
      case 'priceChange6h':
      case 'priceChange24h':
        aValue = parseFloat(a[sortConfig.value.field].replace('%', ''));
        bValue = parseFloat(b[sortConfig.value.field].replace('%', ''));
        break;
    }
    return sortConfig.value.direction === 'asc' ? aValue - bValue : bValue - aValue;
  });
});

const toggleSort = (field) => {
  if (sortConfig.value.field === field) {
    sortConfig.value.direction = sortConfig.value.direction === 'asc' ? 'desc' : 'asc';
  } else {
    sortConfig.value.field = field;
    sortConfig.value.direction = 'desc';
  }
};

const toggleSlideOver = () => {
  isSlideOverOpen.value = !isSlideOverOpen.value;
};

const toggleSmartMoneySlideOver = () => {
  isSmartMoneyCheckOpen.value = !isSmartMoneyCheckOpen.value;
};

const toggleToolsBoxSlideOver = () => {
  isToolsBoxOpen.value = !isToolsBoxOpen.value;
};

const showCommunityDetails = (pool) => {
  currentPoolAddress.value = pool.poolAddress;
  toggleSlideOver();
};

const showSmartMoneyDetails = (pool) => {
  currentPoolAddress.value = pool.poolAddress;
  toggleSmartMoneySlideOver();
};

const showToolsBoxDetail = (pool) => {
  currentPoolAddress.value = pool.poolAddress;
  toggleToolsBoxSlideOver();
};

const handleUpdateAmount = (amount) => {
  quickBuyAmount.value = amount;
};

const copyToClipboard = (pool) => {
  navigator.clipboard.writeText(pool.baseTokenAddress).then(() => {
    pool.copied = true;
    setTimeout(() => {
      pool.copied = false;
    }, 2000);
  });
};

const navigateTo = (pool) => {
  router.push({ path: `/sol_lp/${pool.poolAddress}` });
};

const buyToken = (token) => {
  alert(`快速购买 ${token.name}`);
};

const handleMouseOver = (index) => {
  showDetailsIndex.value = index;
};

const handleMouseLeave = () => {
  showDetailsIndex.value = null;
};

watch(filters, filterPools, { deep: true });

onMounted(() => {
  fetchPoolsData();
  setInterval(fetchPoolsData, 5000); // 定时更新
});
</script>

<style scoped>
.font-heiti {
  font-family: 'HeiTi', sans-serif;
}
</style>