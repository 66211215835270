import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/project/Coins.vue';
import NewCoins from '../views/Home.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'MemeGo ｜ home',
        }
    },
    {
        path: '/newCoins',
        name: 'NewCoins',
        component: NewCoins,
        meta: {
            title: 'MemeGo ｜ New Coins',
        }
    },
    {
        path: '/aboutUs',
        component: () =>
            import('../views/AbotUs.vue'),
        meta: {
            title: 'MemeGo ｜ 关于我们',
        }
    },
    {
        path: '/projects',
        component: () =>
            import('../views/Projects.vue'),
        meta: {
            title: 'MemeGo ｜ 项目',
        }
    },
    {
        path: '/kolIntro',
        component: () =>
            import('../views/kol/Kols.vue'),
        meta: {
            title: 'MemeGo ｜ Kol 信息',
        }
    },
    {
        path: '/myPosition/',
        component: () =>
            import('../views/position/MyPosition.vue'),
        meta: {
            title: 'MemeGo ｜ 我的仓位',
        }
    },
    {
        path: '/pepe',
        component: () =>
            import('../views/sm/Pepe.vue'),
        meta: {
            title: 'MemeGo ｜ Pepe',
        }
    },
    {
        path: '/pengZhuang',
        component: () =>
            import('../views/sm/Pengzhuang.vue'),
        meta: {
            title: 'MemeGo ｜ PengZhuang',
        }
    },
    {
        path: '/twitter',
        component: () =>
            import('../views/sm/Twitter.vue'),
        meta: {
            title: 'MemeGo ｜ Twitter',
        }
    },
    {
        path: '/token/:token',
        component: () =>
            import('../views/trans/TranMain.vue'),
        meta: {
            title: 'MemeGo ｜ Tran',
        }
    },
    {
        path: '/lp/:token',
        component: () =>
            import('../views/trans/TranMain.vue'),
        meta: {
            title: 'MemeGo ｜ 交易详情',
        }
    },
    {
        path: '/base_lp/:token',
        component: () =>
            import('../views/trans/BASETranMain.vue'),
        meta: {
            title: 'MemeGo ｜ 交易详情',
        }
    },
    {
        path: '/bsc_lp/:token',
        component: () =>
            import('../views/trans/BSCTranMain.vue'),
        meta: {
            title: 'MemeGo ｜ 交易详情',
        }
    },
    {
        path: '/sol_lp/:token',
        component: () =>
            import('../views/trans/SolTranMain.vue'),
        meta: {
            title: 'MemeGo ｜ 交易详情',
        }
    },
    {
        path: '/pumpfun/:token',
        component: () =>
            import('../views/trans/PumpfunSolTranMain.vue'),
        meta: {
            title: 'MemeGo ｜ pumpfun',
        }
    },
    {
        path: '/tools',
        component: () =>
            import('../views/sm/Tools.vue'),
        meta: {
            title: 'MemeGo ｜ Tools',
        }
    },
    {
        path: '/guangTou',
        component: () =>
            import('../views/sm/GuangTou.vue'),
        meta: {
            title: 'MemeGo ｜ GuangTou',
        }
    },
    {
        path: '/followBuy',
        component: () =>
            import('../views/sm/FollowBuy.vue'),
        meta: {
            title: 'MemeGo ｜ FollowBuy',
        }
    },
    {
        path: '/ziDian',
        component: () =>
            import('../views/sm/ZiDian.vue'),
        meta: {
            title: 'MemeGo ｜ ZiDian',
        }
    },
    {
        path: '/forward/:social?',
        component: () =>
            import('../views/project/Forward.vue'),
        meta: {
            title: 'MemeGo ｜ 转发群汇总',
        }
    },
    {
        path: '/kolsm',
        component: () =>
            import('../views/project/Kolsm.vue'),
        meta: {
            title: 'MemeGo ｜ KOL 聪明钱',
        }
    },
    {
        path: '/learnMeme',
        component: () =>
            import('../views/project/LearnMeme.vue'),
        meta: {
            title: 'MemeGo ｜ Meme 教程',
        }
    },
    {
        path: '/pumpfunTieZi',
        component: () =>
            import('../views/project/PumpfunTie.vue'),
        meta: {
            title: 'MemeGo ｜ Pumpfun',
        }
    },
    {
        path: '/whatIsNeiro',
        component: () =>
            import('../views/project/WhatIsNeiro.vue'),
        meta: {
            title: 'MemeGo ｜ WhatIsNeiro',
        }
    },
    {
        path: '/gaoShou',
        component: () =>
            import('../views/project/ToBeGaoShou.vue'),
        meta: {
            title: 'MemeGo ｜ GaoShou',
        }
    },
    {
        path: '/zhanBu',
        component: () =>
            import('../views/project/ZhanBu.vue'),
        meta: {
            title: 'MemeGo ｜ ZhanBu',
        }
    },
    {
        path: '/zhuanPan',
        component: () =>
            import('../views/project/ZhuanPan.vue'),
        meta: {
            title: 'MemeGo ｜ ZhuanPan',
        }
    },
    {
        path: '/zhuangZeng',
        component: () =>
            import('../views/project/ZhuangZeng.vue'),
        meta: {
            title: 'MemeGo ｜ ZhuangZeng',
        }
    },
    {
        path: '/laserCat',
        component: () =>
            import('../views/sm/LaserCat.vue'),
        meta: {
            title: 'MemeGo ｜ Web3 LaserCat',
        }
    },
    {
        path: '/cnKol',
        component: () =>
            import('../views/sm/CnKol.vue'),
        meta: {
            title: 'MemeGo ｜ Web3 CnKol',
        }
    },
    {
        path: '/aiScan',
        component: () =>
            import('../views/sm/AiScan.vue'),
        meta: {
            title: 'MemeGo ｜ Web3 AiScan',
        }
    },
    {
        path: '/coins',
        component: () =>
            import('../views/project/Coins.vue'),
        meta: {
            title: 'MemeGo ｜ Coins',
        }
    },
    {
        path: '/monitor',
        component: () =>
            import('../views/project/Monitor.vue'),
        meta: {
            title: 'MemeGo ｜ Monitor',
        }
    },
    {
        path: '/pumpMonitor',
        component: () =>
            import('../views/project/PumpMonitor.vue'),
        meta: {
            title: 'MemeGo ｜ PumpMonitor',
            keepAlive: true
        }
    },
    {
        path: '/pvp',
        component: () =>
            import('../views/Pvp/PvpModel.vue'),
        meta: {
            title: 'MemeGo ｜ PvPModel',
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    },
});

export default router;

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(
        document.querySelectorAll('[data-vue-router-controlled]')
    ).map((el) => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
        .map((tagDef) => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach((tag) => document.head.appendChild(tag));

    next();
});