<template>
  <div class="bg-white w-64 h-full shadow-md p-4 rounded-lg font-heiti" style="height: 200%;">
    <!-- 筛选条件 -->
    <div v-for="(category, index) in categories" :key="index" class="mb-4">
      <button
          @click="toggleCategory(index)"
          class="w-full text-left px-4 py-2 bg-gradient-to-r from-yellow-400 to-gray-200 text-white rounded-md flex justify-between items-center focus:outline-none title-font-size font-bold text-shadow">
        <span class="text-lg font-bold">{{ category.name }}</span>
        <span v-if="category.open" class="text-lg font-bold">-</span>
        <span v-else class="text-lg font-bold">+</span>
      </button>
      <div v-if="category.open" class="mt-2 pl-4 bg-white">
        <div v-for="(option, idx) in category.options" :key="idx" class="mb-2 flex items-center">
          <input
              v-if="category.type === 'radio'"
              type="radio"
              :name="category.name"
              :id="option.id"
              :checked="category.selectedOption === option.id"
              @click="toggleRadioSelection(category, option.id)"
          />
          <input
              v-else
              type="checkbox"
              :id="option.id"
              v-model="option.selected"
              @change="emitSelection(category.name, option.id, option.selected)"
          />
          <label :for="option.id" class="ml-2 text-sm font-normal">{{ option.label }}</label>
        </div>
      </div>
    </div>

    <hr class="my-4 border-gray-300">

    <!-- 我的关注 -->
    <div class="mb-4">
      <h2 class="text-lg font-semibold mb-2 bg-gradient-to-r from-yellow-400 to-gray-200 p-2 rounded text-shadow text-white">我的关注</h2>
      <div class="bg-white p-1 rounded-lg">
        <ul>
          <li v-for="token in favoriteTokens" :key="token.id" class="mb-2 flex items-center text-left cursor-pointer" @click="redirectToTokenPage(token.address)">
            <div class="w-5 h-5 mr-1">
              <img
                  v-if="token.isImageLoaded"
                  :src="token.imageUrl"
                  alt="Token Icon"
                  class="w-full h-full"
                  @error="token.isImageLoaded = false"
              >
            </div>
            <span class="text-sm font-medium text-gray-900 w-24">{{ token.name }}</span>
            <span class="text-sm text-left price w-20">{{ formatPrice(token.baseTokenPriceUsd) }}</span>
            <span
                :class="{'text-green-700': token.h24PriceChange > 0, 'text-red-500': token.h24PriceChange < 0}"
                class="text-sm ml-2"
            >
              {{ token.h24PriceChange }}%
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter();

const emit = defineEmits(['selectionChange']);

const categories = ref([
  {
    name: '标签',
    open: false,
    type: 'radio',
    selectedOption: null,
    options: [
      { id: 'tagAI', label: 'AI MEME' },
      { id: 'tagDoge', label: 'DOGE家族' },
      { id: 'tagCeleb', label: '名人系列' },
      { id: 'tagPepe', label: 'PEPE系列' },
      { id: 'tagTrump', label: '特朗普概念' }
    ]
  },
  {
    name: '池子大小',
    open: true,
    type: 'radio',
    selectedOption: null,
    options: [
      { id: 'poolSize500K', label: '> 500K' },
      { id: 'poolSize1M', label: '> 1M' },
      { id: 'poolSize2M', label: '> 2M' }
    ]
  },
  {
    name: '创建时间',
    open: false,
    type: 'radio',
    selectedOption: null,
    options: [
      { id: 'recent1Day', label: '最近1天' },
      { id: 'recent5Days', label: '最近5天' },
      { id: 'recent10Days', label: '最近10天' },
      { id: 'recent100Days', label: '最近100天' }
    ]
  },
  {
    name: '聪明钱人数',
    open: false,
    type: 'radio',
    selectedOption: null,
    options: [
      { id: 'smartMoney1', label: '>1' },
      { id: 'smartMoney3', label: '>5' },
      { id: 'smartMoney9', label: '>10' }
    ]
  },
  {
    name: 'Dev资金来源',
    open: false,
    type: 'radio',
    selectedOption: null,
    options: [
      { id: 'sourceBinance', label: '币安' },
      { id: 'sourceOKX', label: 'OKX' },
      { id: 'sourceGate', label: 'Gate' },
      { id: 'sourceOthers', label: '其他' }
    ]
  },
  {
    name: '社区含量',
    open: true,
    type: 'checkbox',
    options: [
      { id: 'communityWif', label: 'Wif', selected: false },
      { id: 'communityMumu', label: 'Mumu', selected: false },
      { id: 'communityBilly', label: 'Billy', selected: false },
      { id: 'communityBonk', label: 'Bonk', selected: false }
    ]
  }
]);

const favoriteTokens = ref([]);

const toggleCategory = (index) => {
  categories.value[index].open = !categories.value[index].open;
};

const toggleRadioSelection = (category, optionId) => {
  if (category.selectedOption === optionId) {
    category.selectedOption = null;
    emitSelection(category.name, optionId, false);
  } else {
    category.selectedOption = optionId;
    emitSelection(category.name, optionId, true);
  }
};

const emitSelection = (categoryName, optionId, selected) => {
  emit('selectionChange', { category: categoryName, option: optionId, selected });
};

const fetchFavoriteTokens = async (tokenIds) => {
  const apiKey = 'CG-beckHX7kWeELunRQYy9aESoF';
  const url = `https://pro-api.coingecko.com/api/v3/onchain/networks/eth/pools/multi/${tokenIds.join('%2C')}`;

  try {
    const response = await axios.get(url, {
      headers: {
        accept: 'application/json',
        'x-cg-pro-api-key': apiKey,
      },
    });

    const pools = response.data.data || [];
    const poolDataArray = pools.map((pool) => {
      const attributes = pool.attributes;
      const id = pool.id;
      const name = attributes.name.replace("/ WETH","");
      const baseTokenPriceUsd = attributes.base_token_price_usd;
      const h24PriceChange = attributes.price_change_percentage.h24 || 'N/A';
      const tokenAddress = pool.relationships.base_token.data.id.replace("eth_","");
      const iconUrl = `https://dd.dexscreener.com/ds-data/tokens/ethereum/${tokenAddress}.png`;

      return {
        address:attributes.address,
        id,
        name,
        baseTokenPriceUsd,
        h24PriceChange,
        imageUrl: iconUrl,
        isImageLoaded: true,
        favorite: true,
      };
    });

    favoriteTokens.value = poolDataArray;
  } catch (error) {
    console.error('Error fetching the data:', error);
  }
};

const toggleFavorite = (token) => {
  const favoriteTokens = loadFavoriteTokens();

  if (token.favorite) {
    removeTokenFromFavorites(favoriteTokens, token.address);
  } else {
    addTokenToFavorites(favoriteTokens, token.address);
  }

  token.favorite = !token.favorite;
  saveFavoriteTokens(favoriteTokens);
};

const removeTokenFromFavorites = (favoriteTokens, tokenAddress) => {
  const index = favoriteTokens.indexOf(tokenAddress);
  if (index !== -1) {
    favoriteTokens.splice(index, 1);
  }
};

const isTokenFavorite = (favoriteTokens, tokenAddress) => {
  return favoriteTokens.includes(tokenAddress);
};

const addTokenToFavorites = (favoriteTokens, tokenAddress) => {
  if (!isTokenFavorite(favoriteTokens, tokenAddress)) {
    favoriteTokens.push(tokenAddress);
  }
};

const saveFavoriteTokens = (tokens) => {
  localStorage.setItem('favoriteTokens', JSON.stringify(tokens));
};

const formatPrice = (price) => {
  if (price < 0.0001) {
    let scientificNotation = Number(price).toExponential(4);
    let [base, exponent] = scientificNotation.split('e');
    let formattedBase = "0.0";
    exponent = parseInt(exponent.replace('+', ''));
    let omittedZeros = Math.abs(exponent) - 1;
    let valuePart = base.split('.')[1];
    let subscriptZeros = '';
    for (let digit of omittedZeros.toString()) {
      subscriptZeros += String.fromCharCode(8320 + parseInt(digit));
    }
    let formattedNumber = `$${formattedBase}${subscriptZeros}${valuePart}`;
    return formattedNumber;
  } else {
    return `$${Number(price).toFixed(5)}`;
  }
};

const redirectToTokenPage = (address) => {
  router.push({ path: `/lp/${address}` });
};

const loadFavoriteTokens = () => {
  const storedFavorites = localStorage.getItem('eth_favoriteTokens');
  return storedFavorites ? JSON.parse(storedFavorites) : [];
};

onMounted(() => {
  const storedFavorites = loadFavoriteTokens();
  if (storedFavorites.length) {
    const favoriteTokenIds = storedFavorites.map(token => token.replace('eth_', ''));
    fetchFavoriteTokens(favoriteTokenIds);
  }
});
</script>

<style scoped>
.title-font-size {
  font-size: 1.25rem;
}

.bg-solana-light-purple {
  background: linear-gradient(90deg, #8e44ad 0%, #14F195 100%);
}

.text-shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.font-heiti {
  font-family: 'HeiTi', sans-serif;
}
</style>