<template>
  <div class="flex flex-col h-3/4 bg-gray-100 rounded-lg shadow-lg overflow-hidden">
    <!-- 固定在顶部的标题栏 -->
    <div class="bg-white shadow-md p-4 sticky top-0 z-10">
      <div class="flex items-center space-x-4">
        <img v-if="tokenAddress.address" :src="`https://dd.dexscreener.com/ds-data/tokens/bsc/${tokenAddress.address}.png`" alt="Token Logo" class="w-12 h-12 rounded-full">
        <div>
          <h1 class="text-2xl font-bold text-gray-800">{{ tokenAddress.symbol || '加载中...' }}</h1>
          <p class="text-sm text-gray-500">KOL/聪明钱持仓分析</p>
        </div>
      </div>
      <p v-if="tokenAddress.address" class="mt-2 text-sm text-gray-600">
        <span class="font-mono bg-gray-200 p-1 rounded">{{ tokenAddress.address }}</span>
      </p>
    </div>

    <!-- 内容区域 -->
    <div class="flex-grow overflow-auto p-4">
      <div v-if="loading" class="text-center mt-10">
        <div class="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
        <p class="mt-2 text-blue-500 font-semibold">加载中...</p>
      </div>

      <div v-else-if="!showTrades" class="space-y-4">
        <div class="bg-white shadow-md rounded-lg overflow-hidden">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">KOL 名称</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">持仓百分比</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">持仓数量</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">持仓价值</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">交易详情</th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(kol, index) in kolHoldings" :key="index" class="hover:bg-gray-50">
              <td class="px-6 py-4  text-left  whitespace-nowrap text-sm font-medium text-gray-900">{{ kol.name }}</td>
              <td class="px-6 py-4  text-left  whitespace-nowrap text-sm text-blue-600 font-semibold">{{ kol.holdingPercentage }}%</td>
              <td class="px-6 py-4  text-left  whitespace-nowrap text-sm text-gray-500">{{ formatQuantity(kol.holdingQuantity) }}</td>
              <td class="px-6 py-4  text-left  whitespace-nowrap text-sm text-green-600 font-semibold">{{ kol.holdingValue }}</td>
              <td class="px-6 py-4  text-left  whitespace-nowrap text-sm text-gray-500">
                <button
                    @click="showTradeDetails(kol.address)"
                    class="text-blue-600 hover:text-blue-900 focus:outline-none focus:underline"
                >
                  查看详情
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-else>
        <TradeDetails
            :address="selectedAddress"
            :tokenAddress="tokenAddress.address"
            :tokenSymbol="tokenAddress.symbol"
            @goBack="goBackToHolders"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue';
import axios from 'axios';
import TradeDetails from './TradeDetails.vue';
import dizhi from './ethdizhi.json';

const props = defineProps({
  poolAddress: {
    type: String,
    required: true,
  },
});

const kolHoldings = ref([]);
const loading = ref(true);
const showTrades = ref(false);
const selectedAddress = ref(null);
const tokenAddress = ref({address: '', symbol: ''});

const getTokenMintAddressFromPool = async (poolAddress) => {
  const url = `https://api.dexscreener.com/latest/dex/pairs/bsc/${poolAddress}`;
  try {
    const response = await axios.get(url);
    const baseTokenAddress = response.data.pairs[0].baseToken.address;
    const symbol = response.data.pairs[0].baseToken.symbol;
    const priceUsd = parseFloat(response.data.pairs[0].priceUsd);
    return {tokenMintAddress: baseTokenAddress, symbol, priceUsd};
  } catch (error) {
    console.error('Error fetching token mint address from pool:', error);
    throw error;
  }
};

const fetchTokenHolders = async (tokenAddress) => {
  const options = {
    method: 'GET',
    url: 'https://api.chainbase.online/v1/token/top-holders',
    headers: {
      'x-api-key': '2VOKejecXTzPgIgBNRPjv3q9bRf'
    },
    params: {
      chain_id: 56,
      contract_address: tokenAddress,
      limit: 100
    }
  };

  const allData = [];
  for (let page = 1; page <= 5; page++) {
    const response = await axios({...options, params: {...options.params, page}});
    allData.push(...response.data.data);
  }

  return allData;
};

const fetchAndDisplayTokenHolders = async (poolAddress) => {
  loading.value = true;
  kolHoldings.value = [];

  try {
    const {tokenMintAddress, symbol, priceUsd} = await getTokenMintAddressFromPool(poolAddress);
    tokenAddress.value = {address: tokenMintAddress, symbol};

    const holders = await fetchTokenHolders(tokenMintAddress);
    const totalSupply = holders.reduce((sum, holder) => sum + parseFloat(holder.amount), 0);

    holders.forEach((holder) => {
      const address = holder.wallet_address;
      if (dizhi[address]) {
        const amount = parseFloat(holder.amount);
        const holdingPercentage = ((amount / totalSupply) * 100).toFixed(2);
        const holdingValue = `$${(amount * priceUsd).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`;

        kolHoldings.value.push({
          address: address,
          name: dizhi[address],
          holdingPercentage,
          holdingQuantity: amount,
          holdingValue,
        });
      }
    });

    kolHoldings.value.sort((a, b) => parseFloat(b.holdingPercentage) - parseFloat(a.holdingPercentage));

  } catch (error) {
    console.error('Error fetching token holders:', error);
  } finally {
    loading.value = false;
  }
};

const showTradeDetails = (address) => {
  selectedAddress.value = address;
  showTrades.value = true;
};

const goBackToHolders = () => {
  showTrades.value = false;
};

const formatQuantity = (quantity) => {
  if (quantity >= 1e9) return (quantity / 1e9).toFixed(2) + 'B';
  if (quantity >= 1e6) return (quantity / 1e6).toFixed(2) + 'M';
  if (quantity >= 1e3) return (quantity / 1e3).toFixed(2) + 'K';
  return quantity.toFixed(2);
};

onMounted(() => {
  fetchAndDisplayTokenHolders(props.poolAddress);
});

watch(() => props.poolAddress, (newPoolAddress) => {
  showTrades.value = false;
  fetchAndDisplayTokenHolders(newPoolAddress);
});
</script>