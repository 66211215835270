<template>
  <div class="mx-auto px-1" @click="handleClickOutside">
    <div class="flex">
      <Sidebar class="w-1/7 mr-4 mt-4" @selectionChange="handleSelectionChange" />
      <div class="flex-grow pr-4">
        <TokenTable :filters="filters" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import TokenTable from './SolTokenTable.vue';
import Sidebar from './Sidebar.vue';

const dropdownOpen = ref(false);
const filters = ref({
  poolSize: null,
  creationTime: null,
  burnPool: false,
  additionalIssuance: false,
  relinquishPermissions: false,
  poolSizeSelected:false,
  createTimeSelected:false
});

const timeframes = ref([
  { name: '5M', current: true },
  { name: '30M', current: false },
  { name: '1H', current: false },
  { name: '6H', current: false },
]);


const handleClickOutside = (event) => {
  if (!event.target.closest('.relative')) {
    dropdownOpen.value = false;
  }
};

const handleSelectionChange = (selection) => {
  if (selection.category === '池子大小') {
    filters.value.poolSize = selection.option;
    filters.value.poolSizeSelected = selection.selected;
  } else if (selection.category === '创建时间') {
    filters.value.creationTime = selection.option;
    filters.value.createTimeSelected = selection.selected;
  }
  // 其他筛选条件处理逻辑
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
.container {
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
}
</style>

<style>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}
</style>