// store.js
import { reactive } from 'vue'

// 定义所有选项的映射关系
const options = {
    1: { name: 'SOL', avatar: require('@/assets/logo/memegosol.png') },
    2: { name: 'ETH', avatar: require('@/assets/logo/memegoeth.png') },
    3: { name: 'BASE', avatar: require('@/assets/logo/memegobase.png') },
    4: { name: 'BSC', avatar: require('@/assets/logo/memegobsc.png') }, // 新增 BSC 选项
}

// 从 localStorage 获取存储的选择值
const savedSelectedId = parseInt(localStorage.getItem('selectedPersonId'), 10) || 1

// 定义初始选择值的逻辑
const initialSelected = options[savedSelectedId]
    ? { id: savedSelectedId, ...options[savedSelectedId] }
    : { id: 1, ...options[1] } // 默认选择 SOL

export const store = reactive({
    selected: initialSelected,
    setSelected(newSelected) {
        this.selected = newSelected
        // 更新 localStorage
        localStorage.setItem('selectedPersonId', newSelected.id)
    },
})