<template>
  <tr
      :class="{
      'hovered-row': showDetailsIndex === index,
      'selected-row': showDetailsIndex === index,
      'bg-gray-50': index % 2 !== 0,
      'bg-white': index % 2 === 0
    }"
      @click="$emit('navigateTo', pool)"
      @mouseover="$emit('handleMouseOver', index)"
      @mouseleave="$emit('handleMouseLeave')"
      class="cursor-pointer relative"
  >
    <td class="whitespace-nowrap py-2 pl-2 text-sm text-left sm:pl-0 w-40">
      <div class="flex items-center relative">
        <span v-if="index + 1 <= 3" class="fire-container font-bold">
          <span class="fire">🔥</span>
        </span>
        <div v-if="pool.dexId && pool.dexId.toLowerCase().startsWith('uniswap')" class="flex items-center">
          <img
              :src="require('@/assets/logo/uniswap.png')" alt="Uniswap Logo"
              class="h-4 w-4 ml-2"
          />
          <span class="text-xs ml-1 mr-2 text-gray-500">{{ getDexVersion(pool.dexId) }}</span>
        </div>
        <div class="h-8 w-8 flex-shrink-0 bg-white mr-2" v-if="pool.imageUrl">
          <img class="h-8 w-8 rounded-full" :src="pool.imageUrl" alt="" loading="lazy" @error="handleImageError" />
        </div>
        <div class="h-8 w-8 flex-shrink-0 bg-white mr-2" v-else></div>
        <div class="flex flex-col overflow-hidden">
          <div class="font-medium text-gray-900 truncate w-36">
            <strong>{{ pool.name }}</strong>
            <span class="text-xs">/ ETH</span>
          </div>
          <div class="text-gray-500 text-xs flex items-center">
            <div class="relative mr-2 flex items-center">
              <strong>{{ formatAddress(pool.baseTokenAddress) }}</strong>
            </div>
            <button @click.stop="$emit('copyToClipboard', pool)" class="copy-button">
              <i class="fas fa-copy text-gray-500"></i>
            </button>
            <span v-if="pool.copied" class="tooltip">Copied!</span>
          </div>
        </div>
      </div>
    </td>
    <td class="whitespace-nowrap py-2 text-sm text-left w-20">
      <span class="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xm font-medium text-green-700">
        {{ pool.tagValue || "" }}
      </span>
    </td>
    <td class="whitespace-nowrap py-2 text-xs text-left relative w-28">
      <div class="inline-block bg-gray-100 rounded-md p-1">
        <div class="w-20 h-6 rounded-md p-1 flex items-center justify-center">
          <button @click.stop="$emit('buyToken', pool)" class="relative w-full h-full bg-transparent rounded-md flex items-center justify-center">
            <span class="mr-3">⚡️</span>
            <img src="https://cryptologos.cc/logos/ethereum-eth-logo.png?v=014" alt="ETH" class="w-4 h-4 mr-3">
            <span class="text-blue-500 font-bold text-sm">{{ quickBuyAmount }}</span>
          </button>
        </div>
      </div>
    </td>
    <td class="whitespace-nowrap py-2 text-sm text-left w-24">
      <div class="text-gray-900 text-lg font-bold">{{ pool.price }}</div>
      <div class="text-gray-500 text-xs mt-1">${{ pool.price }}</div>
    </td>
    <td class="whitespace-nowrap py-2 text-sm text-left w-28">
      <div class="text-gray-900"><strong>{{ pool.poolCreatedTime }}</strong></div>
    </td>
    <td class="whitespace-nowrap py-2 text-sm text-left w-28">
      <div class="text-gray-900"><strong>${{ pool.reserve }}</strong></div>
      <div class="flex items-left mt-1">
        <img src="https://cryptologos.cc/logos/ethereum-eth-logo.png?v=014" alt="ETH" class="w-4 h-4 mr-1">
        <div class="text-xs text-gray-400"><strong>{{ pool.ethNum }}</strong></div>
      </div>
    </td>
    <td class="whitespace-nowrap py-2 text-sm text-left w-24">
      <div class="text-gray-900"><strong>${{ pool.fdv_usd }}</strong></div>
    </td>
    <td class="whitespace-nowrap py-2 text-sm text-left w-24">
      <div class="text-gray-900"><strong>{{ pool.buys24h }}</strong></div>
      <div class="text-xs">
        <span class="text-green-700"><strong>{{ pool.buys }}</strong></span> / <span class="text-red-500"><strong>{{ pool.sells }}</strong></span>
      </div>
    </td>
    <td class="whitespace-nowrap py-2 text-sm text-left w-24">
      <div class="text-gray-900"><strong>${{ pool.volume24h }}</strong></div>
    </td>
    <td v-if="showDetailsIndex !== index" class="whitespace-nowrap py-2 text-sm text-left w-20" :style="{ color: getPriceChangeColor(pool.priceChange5m) }">
      <strong>{{ removeNegativeSign(pool.priceChange5m) }}%</strong>
    </td>
    <td v-if="showDetailsIndex !== index" class="whitespace-nowrap py-2 text-sm text-left w-20" :style="{ color: getPriceChangeColor(pool.priceChange1h) }">
      <strong>{{ removeNegativeSign(pool.priceChange1h) }}%</strong>
    </td>
    <td v-if="showDetailsIndex !== index" class="whitespace-nowrap py-2 text-sm text-left w-20" :style="{ color: getPriceChangeColor(pool.priceChange6h) }">
      <strong>{{ removeNegativeSign(pool.priceChange6h) }}%</strong>
    </td>
    <td v-if="showDetailsIndex !== index" class="whitespace-nowrap py-2 text-sm text-left w-20" :style="{ color: getPriceChangeColor(pool.priceChange24h) }">
      <strong>{{ removeNegativeSign(pool.priceChange24h) }}%</strong>
    </td>
    <td v-else class="whitespace-nowrap py-2 text-sm font-light text-left" colspan="6">
      <div class="absolute right-0 top-0 bg-blue-200 bg-opacity-75 w-1/4 h-full z-10 flex justify-center items-center space-x-4">
        <button @click.stop="$emit('showCommunityDetails', pool)" class="text-gray-800 hover:underline font-bold">查看社区含量</button>
        <button @click.stop="$emit('showSmartMoneyDetails', pool)" class="text-gray-800 hover:underline font-bold">查看是否有聪明钱</button>
        <button @click.stop="$emit('showToolsBoxDetail', pool)" class="text-gray-800 hover:underline font-bold">使用工具箱</button>
      </div>
    </td>
  </tr>
</template>

<script setup>
const props = defineProps({
  pool: Object,
  index: Number,
  showDetailsIndex: Number,
  quickBuyAmount: Number
});

const emit = defineEmits([
  'showCommunityDetails',
  'showSmartMoneyDetails',
  'showToolsBoxDetail',
  'handleMouseOver',
  'handleMouseLeave',
  'navigateTo',
  'copyToClipboard',
  'buyToken'
]);

const getDexVersion = (dexId) => {
  if (dexId && dexId.toLowerCase().startsWith('uniswap')) {
    const parts = dexId.split('_');
    if (parts.length > 1) {
      return parts[1].toUpperCase();
    }
  }
  return '';
};

const formatAddress = (address) => {
  if (!address) return '';
  const firstPart = address.slice(0, 6);
  const lastPart = address.slice(-4);
  return `${firstPart}...${lastPart}`;
};

const getPriceChangeColor = (value) => {
  if (value === 'N/A') return 'gray';
  return parseFloat(value) >= 0 ? 'green' : 'red';
};

const removeNegativeSign = (value) => {
  return value.replace('-', '');
};

const handleImageError = (event) => {
  event.target.style.display = 'none';
};
</script>

<style scoped>
.hovered-row {
  background-color: #f0f8ff;
}
.selected-row {
  background-color: #f0f8ff;
}
.fire-container {
  display: inline-flex;
  align-items: center;
}
.fire {
  display: inline-block;
  position: relative;
  font-size: 1.5rem;
  animation: burn 1s infinite;
}
@keyframes burn {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}
.fire::before,
.fire::after {
  content: '🔥';
  position: absolute;
  top: 0;
  left: 0;
  animation: flicker 0.5s infinite alternate;
}
.fire::before {
  transform: translate(-10%, -10%) scale(0.8);
  opacity: 0.6;
}
.fire::after {
  transform: translate(10%, 10%) scale(0.8);
  opacity: 0.6;
}
@keyframes flicker {
  0% { opacity: 0.8; }
  100% { opacity: 1; }
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 10px;
  opacity: 0.8;
}
</style>