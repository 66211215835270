<script setup>
import {ref, watch} from 'vue';
import axios from 'axios';
import { PublicKey, Connection } from '@solana/web3.js';
import yinglidizhi from './yinglidizhi.json';
import { getMint } from '@solana/spl-token';
import tokenAvatars from './tokenAvatars.json'


const connection = new Connection('https://mainnet.helius-rpc.com/?api-key=56675e7d-eb04-4bcd-886d-4231c63cfb56', 'confirmed');

const props = defineProps({
  poolAddress: {
    type: String,
    required: true,
  },
});

const selectedPoolCommunityContent = ref([]);
const loading = ref(false);

// 独立的异步函数来获取 totalSupply
const getTotalSupply = async (tokenMintAddress) => {
  try {
    const tokenMintPublicKey = new PublicKey(tokenMintAddress);
    const mintInfo = await getMint(connection, tokenMintPublicKey);
    const totalSupply = Number(mintInfo.supply) / 10 ** mintInfo.decimals;
    return totalSupply;
  } catch (error) {
    console.error('Error fetching total supply:', error);
    throw error;
  }
};

// 格式化金额
const formatAmount = (amount) => {
  if (amount >= 1e9) return (amount / 1e9).toFixed(2) + 'B';
  if (amount >= 1e6) return (amount / 1e6).toFixed(2) + 'M';
  if (amount >= 1e3) return (amount / 1e3).toFixed(2) + 'K';
  return amount.toString();
};

// 异步更新社区的持有量占比
const updatePercentageForCommunity = async (community, totalAmount, totalSupply) => {
  const percentage = ((totalAmount / totalSupply) * 100).toFixed(2);
  community.percentage = percentage;
};

const getTokenMintAddressFromPool = async (poolAddress) => {
  const url = `https://api.dexscreener.com/latest/dex/pairs/solana/${poolAddress}`;
  try {
    const response = await axios.get(url);
    const baseTokenAddress = response.data.pairs[0].baseToken.address;
    const symbol = response.data.pairs[0].baseToken.symbol;
    const priceUsd = parseFloat(response.data.pairs[0].priceUsd);
    return { tokenMintAddress: baseTokenAddress, symbol, priceUsd };
  } catch (error) {
    console.error('Error fetching token mint address from pool:', error);
    throw error;
  }
};

// 主函数，获取和显示 Token 持有者信息
const fetchAndDisplayTokenHolders = async (poolAddress) => {
  loading.value = true;
  selectedPoolCommunityContent.value = []; // 初始化

  try {
    const { tokenMintAddress, symbol, priceUsd } = await getTokenMintAddressFromPool(poolAddress);

    // 并发获取 totalSupply
    const totalSupplyPromise = getTotalSupply(tokenMintAddress);

    const requestOptions = {
      method: 'get',
      headers: {
        'token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE3MjM3Njg4NTc1NTYsImVtYWlsIjoiamlhbmhhb2JpYW81MDJAZ21haWwuY29tIiwiYWN0aW9uIjoidG9rZW4tYXBpIiwiYXBpVmVyc2lvbiI6InYyIiwiaWF0IjoxNzIzNzY4ODU3fQ.p88squ6AxqhlfnCzlcDv0GylTCnQaCPkvpFfofNBDP0'
      }
    };

    const fetchPage = async (page) => {
      const url = `https://pro-api.solscan.io/v2.0/token/holders?address=${tokenMintAddress}&page=${page}&page_size=40`;
      const response = await axios.get(url, requestOptions);
      return response.data.data.items;
    };

    const holdersData = await Promise.all(
        Array.from({ length: 20 }, (_, i) => fetchPage(i + 1))
    );

    const allHolders = holdersData.flat();

    // 获取 totalSupply 的值
    const totalSupply = await totalSupplyPromise;

    // 创建一个临时对象，存储不同社区的地址和统计数据
    const communityHolders = {};

    allHolders.forEach((holder) => {
      const owner = holder.owner;
      const community = yinglidizhi[owner]; // 从 yinglidizhi.json 中获取对应社区

      const amount = Math.floor(holder.amount / 10 ** holder.decimals);
      const holdingValue = amount * priceUsd;

      if (community === symbol) {
        return;
      }

      if (community) {
        if (!communityHolders[community]) {
          communityHolders[community] = {
            addresses: [],
            totalAmount: 0,
            totalHoldingValue: 0,
            percentage: null, // 初始化为 null
          };
        }
        communityHolders[community].addresses.push(owner);
        communityHolders[community].totalAmount += amount;
        communityHolders[community].totalHoldingValue += holdingValue;
      }
    });

    // 将临时对象转换为数组格式，赋值给 selectedPoolCommunityContent
    selectedPoolCommunityContent.value = Object.keys(communityHolders).map((community) => {
      const totalAmount = communityHolders[community].totalAmount;
      const communityData = {
        name: community,
        addresses: communityHolders[community].addresses,
        totalAmount: formatAmount(totalAmount),
        totalHoldingValue: communityHolders[community].totalHoldingValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        percentage: null, // 初始值为 null
      };
      // 异步更新持有量占比
      updatePercentageForCommunity(communityData, totalAmount, totalSupply);
      return communityData;
    });

  } catch (error) {
    console.error('Error fetching token holders:', error);
  } finally {
    loading.value = false;
  }
};

// 调用函数获取并展示数据
fetchAndDisplayTokenHolders(props.poolAddress);

watch(() => props.poolAddress, (newAddress) => {
  if (newAddress && !loading.value) {
    fetchAndDisplayTokenHolders(newAddress);
  }
});


</script>

<template>
  <div class="p-3">

    <div v-if="loading" class="text-center text-gray-500">加载中...</div>
    <div v-else>
      <!-- 标题栏 -->
      <div class="bg-gray-200 p-1 rounded-lg shadow-lg flex items-center font-bold">
        <div class="w-1/4 text-left text-gray-600">社区</div>
        <div class="w-1/6 text-gray-600">优质成员数</div>
        <div class="w-1/6 text-gray-600">持有</div>
        <div class="w-1/6 text-gray-600">价值</div>
        <div class="w-1/6 text-gray-600">占比</div>
      </div>

      <div class="space-y-2 max-h-96 overflow-y-auto mt-2">
        <div
            v-for="(community, index) in selectedPoolCommunityContent"
            :key="index"
            class="bg-white p-1 rounded-lg shadow-lg hover:shadow-xl transition-shadow flex items-center"
        >
          <!-- 头像和名字 -->
          <div class="flex items-center space-x-4 w-1/4">
            <img
                :src="tokenAvatars[community.name] || 'default-avatar.png'"
                alt="Avatar"
                class="w-8 h-8 rounded-full border-2 border-gray-300"
            />
            <p class="text-lg font-bold text-gray-900">{{ community.name }}</p>
          </div>

          <!-- 成员数 -->
          <div class="w-1/6">
            <p class="text-sm text-blue-600 font-medium">{{ community.addresses.length }}</p>
          </div>

          <!-- 持有 -->
          <div class="w-1/6">
            <p class="text-sm text-blue-600 font-medium">{{ community.totalAmount }}</p>
          </div>

          <!-- 价值 -->
          <div class="w-1/6">
            <p class="text-sm text-green-700 font-medium">${{ community.totalHoldingValue }}</p>
          </div>

          <!-- 占比 -->
          <div class="w-1/6">
            <p class="text-sm text-purple-600 font-medium">{{ community.percentage }}%</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* 你可以在这里添加额外的样式 */
</style>