<template>
  <div class="bg-white w-64 h-full shadow-md p-4 rounded-lg font-heiti" style="height: 200%;">
    <div v-for="(category, index) in categories" :key="index" class="mb-4">
      <button
          @click="toggleCategory(index)"
          class="w-full text-left px-4 py-2 bg-solana-light-purple text-white rounded-md flex justify-between items-center focus:outline-none title-font-size text-shadow">
        <span class="text-lg font-bold">{{ category.name }}</span>
        <span v-if="category.open" class="text-lg font-bold">-</span>
        <span v-else class="text-lg font-bold">+</span>
      </button>
      <div v-if="category.open" class="mt-2 pl-4">
        <div v-for="(option, idx) in category.options" :key="idx" class="mb-2 flex items-center">
          <input
              v-if="category.type === 'radio'"
              type="radio"
              :name="category.name"
              :id="option.id"
              :checked="category.selectedOption === option.id"
              @click="toggleRadioSelection(category, option.id)"
          />
          <input
              v-else
              type="checkbox"
              :id="option.id"
              v-model="option.selected"
              @change="emitSelection(category.name, option.id, option.selected)"
          />
          <label :for="option.id" class="ml-2 text-sm font-bold">{{ option.label }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const emit = defineEmits(['selectionChange']);

const categories = ref([
  {
    name: '池子大小',
    open: true,
    type: 'radio',
    selectedOption: null,
    options: [
      {id: 'poolSize10K', label: '> 10K'},
      {id: 'poolSize50K', label: '> 50K'},
      {id: 'poolSize100K', label: '> 100K'},
      {id: 'poolSize500K', label: '> 500K'}
    ]
  },
  {
    name: '创建时间',
    open: true,
    type: 'radio',
    selectedOption: null,
    options: [
      {id: 'recent5m', label: '<5分钟'},
      {id: 'recent10m', label: '<10分钟'},
      {id: 'recent30m', label: '<30分钟'},
      {id: 'recent100m', label: '<100分钟'}
    ]
  },
  {
    name: '聪明钱人数',
    open: false,
    type: 'radio',
    selectedOption: null,
    options: [
      {id: 'smartMoney1', label: '>1'},
      {id: 'smartMoney3', label: '>5'},
      {id: 'smartMoney9', label: '>10'}
    ]
  },
  {
    name: 'Dev资金来源',
    open: false,
    type: 'radio',
    selectedOption: null,
    options: [
      {id: 'sourceBinance', label: '币安'},
      {id: 'sourceOKX', label: 'OKX'},
      {id: 'sourceGate', label: 'Gate'},
      {id: 'sourceOthers', label: '其他'}
    ]
  },
  // {
  //   name: '社区含量',
  //   open: false,
  //   type: 'checkbox',
  //   options: [
  //     {id: 'communityPepe', label: 'pepe', selected: false},
  //     {id: 'communityMog', label: 'mog', selected: false},
  //     {id: 'communityPeipei', label: 'peipei', selected: false},
  //     {id: 'communityMaga', label: 'maga', selected: false}
  //   ]
  // }
]);

const toggleCategory = (index) => {
  categories.value[index].open = !categories.value[index].open;
};

const toggleRadioSelection = (category, optionId) => {
  if (category.selectedOption === optionId) {
    category.selectedOption = null;
    emitSelection(category.name, optionId, false);
  } else {
    category.selectedOption = optionId;
    emitSelection(category.name, optionId, true);
  }
};

const emitSelection = (categoryName, optionId, selected) => {
  emit('selectionChange', {category: categoryName, option: optionId, selected});
};
</script>

<style scoped>
.title-font-size {
  font-size: 1.25rem; /* 增大标题字体 */
}

.bg-solana-light-purple {
  background: linear-gradient(90deg, #8e44ad 0%, #14F195 100%); /* 与按钮相同的渐变背景颜色 */
}

.text-shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* 添加阴影效果，使白色字体更亮眼 */
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}
</style>