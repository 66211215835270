<script setup>

import { ref} from "vue";

const isVisible = ref(false);

</script>

<template>
  <!-- This empty div is only for demo purposes and it's used so you can close the menu on a touchscreen device. Normally you'd handle it differently by not using hover states on mobile, but by using real clicks. -->
  <div class="inset-0" @click="isVisible = false"></div>
  <div class="relative inline-block" @mouseover="isVisible = true" @mouseleave="isVisible = false" @keydown.enter="isVisible = !isVisible">
    <router-link
        to="/newCoins"
        class="font-general-medium block text-left text-base font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-3 mb-1.5 sm:py-1.5 border-t-2 pt-2.5 sm:pt-1.5 sm:border-t-0 border-primary-light dark:border-secondary-dark whitespace-nowrap"
        aria-label="CewComer">
      新币    </router-link>
  </div>

</template>

<style scoped>

</style>
