<template>
  <div class="p-0.5">
    <!-- 新增的标题栏 -->
    <div class="flex justify-between items-center bg-gray-100 p-1 mb-0.5">
      <div class="flex-1 text-center text-xs">
        <div class="leading-tight">持仓数量</div>
        <div class="font-bold leading-tight text-sm">100</div> <!-- 这里可以替换成动态值 -->
      </div>
      <div class="flex-1 text-center text-xs">
        <div class="leading-tight">市场价值</div>
        <div class="font-bold leading-tight text-sm">$150,000</div> <!-- 这里可以替换成动态值 -->
      </div>
      <div class="flex-1 text-center text-xs">
        <div class="leading-tight">盈亏</div>
        <div class="font-bold text-green-700 leading-tight text-sm">$20</div> <!-- 这里可以替换成动态值 -->
      </div>
      <div class="flex-1 text-center text-xs">
        <div class="leading-tight">收益率</div>
        <div class="font-bold text-red-500 leading-tight text-sm">-5%</div> <!-- 这里可以替换成动态值 -->
      </div>
      <button  @click="goBackToHolders"
           class="ml-1 text-xs bg-red-500 text-white py-0.5 px-1.5 rounded hover:bg-blue-600">
        返回
      </button>
      <button class="ml-1 text-xs bg-blue-500 text-white py-0.5 px-1.5 rounded hover:bg-blue-600">
        收益图
      </button>
    </div>



    <!-- 优化的买入和卖出统计行 -->
    <div class="flex justify-between items-center mb-0.5">
      <div class="flex-1 text-center text-xs bg-green-100 p-3 rounded flex-grow-2">
        <div class="leading-tight"><strong>买入数量：50，价值 $75,000</strong></div>
      </div>
      <div class="flex-1 text-center text-xs bg-red-100 p-3 rounded ml-1 flex-grow-2">
        <div class="leading-tight"><strong>卖出数量：30，价值 $45,000</strong></div>
      </div>
    </div>


    <div :class="{ 'min-h-96': trades.length === 0 }">
      <table class="min-w-full bg-white text-left">
        <thead>
        <tr class="bg-blue-500 text-white text-xs">
          <th class="py-2 px-4 border-b border-gray-200">时间</th>
          <th class="py-2 px-4 border-b border-gray-200">类型</th>
          <th class="py-2 px-4 border-b border-gray-200">USD</th>
          <th class="py-2 px-4 border-b border-gray-200">{{ tokenSymbol }}</th>
          <th class="py-2 px-4 border-b border-gray-200">SOL</th>
          <th class="py-2 px-4 border-b border-gray-200">价格</th>
          <th class="py-2 px-4 border-b border-gray-200">交易人</th>
          <th class="py-2 px-4 border-b border-gray-200">TX</th>
        </tr>
        </thead>
        <tbody v-if="trades.length === 0">
        <tr>
          <td colspan="9" class="text-gray-500 text-center py-4">加载中...</td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr
            v-for="(trade, index) in trades"
            :key="index"
            :class="[
              'text-sm font-bold',
              trade.type === 'buy'
                ? 'text-green-700'
                : trade.type === 'Transfer'
                ? 'text-gray-500'
                : 'text-red-500'
            ]"
        >
          <td class="py-2 px-4 border-b border-gray-200 text-xs">
            {{ formatTimestamp(trade.blockTime) }}
          </td>
          <td class="py-2 px-4 border-b border-gray-200 text-xs">{{ trade.type }}</td>
          <td class="py-2 px-4 border-b border-gray-200 text-xs">
            {{ (trade.solAmountChanged * 150).toFixed(2) }}
          </td>
          <td class="py-2 px-4 border-b border-gray-200 text-xs">
            {{ Math.floor(trade.tokenAmountChanged) }}
          </td>
          <td class="py-2 px-4 border-b border-gray-200 text-xs">
            {{ trade.solAmountChanged.toFixed(3) }}
          </td>
          <td class="py-2 px-4 border-b border-gray-200 text-xs">{{ trade.price }}</td>
          <td class="py-2 px-4 border-b border-gray-200 text-xs flex items-center">
            <a
                :href="'https://solscan.io/address/' + trade.trader"
                target="_blank"
                class="text-blue-500"
            >
              {{ formatAddress(trade.trader) }}
            </a>
            <button
                @click="goBackToHolders"
                class="ml-2 text-gray-500 hover:text-red-500 focus:outline-none"
            >
              <i class="fas fa-times"></i>
            </button>
          </td>
          <td class="py-2 px-4 border-b border-gray-200 text-xs">
            <a
                :href="'https://solscan.io/tx/' + trade.signature"
                target="_blank"
                class="text-blue-500"
            >
              <i class="fas fa-share"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import * as solanaWeb3 from '@solana/web3.js';
import PQueue from 'p-queue';

const props = defineProps(['address', 'tokenAddress', 'tokenSymbol']);
const emit = defineEmits(['goBack']);
const trades = ref([]);
const loading = ref(true);

const RPC_URL = 'https://mainnet.helius-rpc.com/?api-key=56675e7d-eb04-4bcd-886d-4231c63cfb56';
const connection = new solanaWeb3.Connection(RPC_URL, 'finalized');
const concurrencyLimit = 10;
const queue = new PQueue({ concurrency: concurrencyLimit });

watch([() => props.address, () => props.tokenAddress], ([newAddress, newTokenAddress]) => {
  if (newAddress && newTokenAddress) {
    fetchTrades(newAddress, newTokenAddress);
  }
});

onMounted(() => {
  if (props.address && props.tokenAddress) {
    fetchTrades(props.address, props.tokenAddress);
  }
});

async function fetchTrades(publicKey, tokenMintAddress) {

  console.log("tokenMintAddress"+tokenMintAddress)
  console.log("publicKey"+publicKey)

  const publicKeyObj = new solanaWeb3.PublicKey(publicKey);
  const tokenPublicKey = new solanaWeb3.PublicKey(tokenMintAddress);
  trades.value = [];

  const signatures = await connection.getSignaturesForAddress(publicKeyObj, { limit: 50 });

  await queue.addAll(signatures.map(({ signature }) => async () => {
    const result = await processTransaction(connection, signature, tokenPublicKey, publicKeyObj);
    if (result) {
      trades.value = [...trades.value, ...result].reduce((acc, tx) => {
        const existingTx = acc.find(t => t.signature === tx.signature);
        if (!existingTx) {
          acc.push(tx);
        }
        return acc;
      }, []);
    }
  }));

  loading.value = false;
}

function formatTimestamp(timestamp) {
  const date = new Date(timestamp * 1000); // 将时间戳转换为毫秒

  const yyyy = date.getFullYear(); // 获取四位年份
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // 获取月份，并补齐两位
  const dd = String(date.getDate()).padStart(2, '0'); // 获取日期，并补齐两位

  const hh = String(date.getHours()).padStart(2, '0'); // 获取小时，并补齐两位
  const min = String(date.getMinutes()).padStart(2, '0'); // 获取分钟，并补齐两位
  const ss = String(date.getSeconds()).padStart(2, '0'); // 获取秒数，并补齐两位

  return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
}

async function processTransaction(connection, signature, tokenMintAddress, publicKey) {
  try {
    // 确保 tokenMintAddress 是一个 PublicKey 对象
    const tokenMintPublicKey = tokenMintAddress instanceof solanaWeb3.PublicKey
        ? tokenMintAddress
        : new solanaWeb3.PublicKey(tokenMintAddress);

    const transaction = await connection.getTransaction(signature, {
      maxSupportedTransactionVersion: 0
    });

    if (transaction && transaction.meta && transaction.meta.err === null) {
      const postTokenBalances = transaction.meta.postTokenBalances || [];
      const preTokenBalances = transaction.meta.preTokenBalances || [];
      const preBalances = transaction.meta.preBalances || [];
      const postBalances = transaction.meta.postBalances || [];

      let tokenTransactions = [];

      postTokenBalances.forEach((balance, index) => {
        if (balance.mint === tokenMintPublicKey.toBase58()) {
          const preBalance = preTokenBalances[index];
          const amountChanged = Math.abs(balance.uiTokenAmount.uiAmount - (preBalance?.uiTokenAmount.uiAmount || 0));
          const solChanged = Math.abs((postBalances[0] - preBalances[0]) / solanaWeb3.LAMPORTS_PER_SOL);

          let type = 'Transfer';
          if (solChanged !== 0) {
            type = (postBalances[0] - preBalances[0]) > 0 ? 'sell' : 'buy';
          }

          tokenTransactions.push({
            signature: transaction.transaction.signatures[0],
            blockTime: transaction.blockTime,
            type,
            tokenAmountChanged: amountChanged,
            solAmountChanged: solChanged,
            trader: publicKey.toBase58(),
          });
        }
      });

      return tokenTransactions;
    }
  } catch (error) {
    console.error(`Error processing transaction ${signature}:`, error);
  }
  return [];
}

function goBackToHolders() {
  emit('goBack');
}

function formatAddress(address) {
  return address.slice(0, 5);
}
function formatTimeDiff(blockTime) {
  const now = Date.now() / 1000;
  const diff = now - blockTime;

  const days = Math.floor(diff / 86400);
  const hours = Math.floor((diff % 86400) / 3600);
  const minutes = Math.floor((diff % 3600) / 60);
  const seconds = Math.floor(diff % 60); // 计算秒数

  let formattedTime = '';

  if (days > 0) {
    formattedTime += `${days}d `;
  }
  if (hours > 0 || days > 0) {
    formattedTime += `${hours}h `;
  }
  if (minutes > 0 || hours > 0 || days > 0) {
    formattedTime += `${minutes}m `;
  } else if (seconds > 0) {
    // 当分钟数为0时显示秒数
    formattedTime += `${seconds}s`;
  }

  return formattedTime.trim();
}
</script>

<style scoped>
.text-center {
  text-align: center;
}

.text-gray-500 {
  color: #6b7280;
}

.bg-blue-500 {
  background-color: #4a90e2;
}

.text-green-700 {
  color: #10b981;
}

.text-red-500 {
  color: #ef4444;
}

.text-blue-500 {
  color: #4a90e2;
}

.text-blue-500:hover {
  color: #357abd;
}

.border-b {
  border-bottom-width: 1px;
}

.border-gray-200 {
  border-color: #edf2f7;
}

.min-h-96 {
  min-height: 2rem;
}

/* 新增样式：调整标题栏高度和间距 */
.flex-1 {
  padding: 4px 0; /* 调整上下间距 */
}

.bg-gray-100 {
  padding: 4px 8px; /* 调整标题栏整体的内边距 */
  margin-bottom: 8px; /* 调整标题栏与下方内容的间距 */
}

.text-xs {
  font-size: 12px; /* 适当减小文字大小 */
}
</style>