<template>
  <div>
    <div class="full-width-container mx-auto w-full overflow-hidden">
      <component :is="selectedComponent" />
    </div>
  </div>
</template>

<script setup>
import {inject, computed, watch} from 'vue'
import Homepage from '@/views/Homepage.vue'
import BaseHomepage from '@/views/BaseHomepage.vue'
import SolHomepage from '@/views/SolHomepage.vue'
import BscHomepage from '@/views/BscHomepage.vue'

const store = inject('store')

// 创建映射关系，根据 store.selected.name 对应不同的组件
const componentMap = {
  'SOL': SolHomepage,
  'ETH': Homepage,
  'BASE': BaseHomepage,
  'BSC': BscHomepage,
}

// 使用 computed 进行动态组件选择
const selectedComponent = computed(() => {
  return componentMap[store.selected.name] || Homepage
})

// 监听 store.selected 的变化
watch(() => store.selected, (newVal) => {
  console.log('Selected changed to:', newVal)
})
</script>

<style scoped>
.full-width-container {
  margin: 0;
  width: 100%;
  position: relative;
  padding-left: 0; /* 移除边距 */
  padding-right: 0; /* 移除边距 */
  box-sizing: border-box; /* 确保内边距不会影响宽度 */
}
</style>

<style>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
</style>