<template>
  <div class="p-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
    <div v-for="tool in tools" :key="tool.id" class="bg-white shadow rounded-lg p-4 flex flex-col items-center">
      <img :src="tool.img" alt="Tool Image" class="w-20 h-20 object-cover mb-2 rounded-lg">
      <h3 class="text-xs font-black font-sans text-center">{{ tool.title }}</h3>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  poolAddress: {
    type: String,
    required: true,
  },
});

// 工具数据
const tools = [
  {
    id: 5,
    title: '社交提及总结',
    img: require('@/assets/eth/learnMeme.png'),
    link: '/learnMeme'
  },
  {
    id: 0,
    title: '分析聪明钱',
    img: require('@/assets/eth/pepe.png'),
    link: '/pepe'
  },
  {
    id: 7,
    title: '和热门项目地址比较',
    img: require('@/assets/eth/jiaocha.png'),
    link: '/pengZhuang'
  },
  {
    id: 3,
    title: 'AI分析Token',
    img: require('@/assets/eth/aicoin.png'),
    link: '/aiScan'
  },
  {
    id: 4,
    title: '代币发售后的事件分析',
    img: require('@/assets/eth/gendan.png'),
    link: '/followBuy'
  }
];

</script>

<style scoped>
img {
  border-radius: 0.5rem; /* 圆角 */
}

h3 {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* 幼体黑体 */
}
</style>