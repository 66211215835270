<script setup>
import { inject, computed } from 'vue';
import SearchInput from './SearchInput.vue';
import NewCoins from "@/components/shared/NewCoins.vue";
import HotMenu from "@/components/shared/HotMenu.vue";
import '@fortawesome/fontawesome-free/css/all.css';
import Pumpfun from "@/components/shared/Pumpfun.vue";
import Forward from "@/components/shared/Forward.vue";
import Monitor from "@/components/shared/Monitor.vue";
import PvpModel from "@/components/shared/PvpModel.vue";
import AboutUs from "@/components/shared/AboutUs.vue";

const props = defineProps({
  showModal: Boolean,
  isOpen: Boolean,
});

const store = inject('store'); // 确保你的 store 包含 selected 的状态

const selected = computed(() => store.selected); // 计算属性获取当前选中的选项

const handleLinkClick = () => {
  // 你现有的 handleLinkClick 逻辑
};
</script>

<template>
  <div :class="isOpen ? 'block' : 'hidden'"
       class="sm:flex justify-start items-center shadow-lg sm:shadow-none relative w-4/5">
    <div class="flex items-center w-full">

      <HotMenu/>

      <NewCoins/>

      <Monitor class="mr-4"/>

      <Forward class="mr-4"/>

      <SearchInput class="ml-4 mr-4"/>

      <Pumpfun class="mr-4"/>

      <AboutUs/>

<!--      <PvpModel/>-->

    </div>
  </div>
</template>

<style scoped>
/* 这里可以添加 scoped 样式来进一步优化你的组件样式 */
.select-menus-custom-width {
  width: 400px; /* 固定宽度为400px */
}

.text-red-500 {
  color: #f56565; /* 这是 Tailwind CSS 中的红色 */
}
</style>