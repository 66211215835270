<!-- PoolTable.vue -->
<template>
  <div class="overflow-x-auto flex-1" style="max-height: 800px;">
    <div class="inline-block min-w-full py-2 align-middle">
      <table class="min-w-full divide-y divide-gray-300">
        <thead>
        <tr class="align-middle">
          <th v-for="header in tableHeaders" :key="header.key" scope="col" class="pt-0.5 pb-1 pl-2 pr-3 text-left text-sm font-black text-gray-500 sm:pl-0">
            {{ header.label }}
            <button v-if="header.sortable" @click="$emit('toggleSort', header.key)" class="ml-1 focus:outline-none">
              <svg v-if="sortConfig.key !== header.key" class="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"></path>
              </svg>
              <svg v-else-if="sortConfig.direction === 'asc'" class="w-4 h-4 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path>
              </svg>
              <svg v-else class="w-4 h-4 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </button>
          </th>
        </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
        <template v-for="(pool, index) in filteredPools" :key="pool.id || pool.poolAddress">
          <tr
              :class="{
                'hovered-row': showDetailsIndex === index,
                'selected-row': showDetailsIndex === index,
                'bg-gray-50': index % 2 !== 0,
                'bg-white': index % 2 === 0
              }"
              @click="$emit('navigateTo', pool)"
              @mouseover="$emit('handleMouseOver', index)"
              @mouseleave="$emit('handleMouseLeave')"
              class="cursor-pointer relative"
          >
            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-left sm:pl-0 relative">
              <div class="flex items-center">
                  <span v-if="index + 1 <= 3" class="fire-container font-bold">
                    <span class="fire">🔥</span>
                  </span>
                <img
                    v-if="pool.dexId === 'raydium'"
                    :src="require('@/assets/logo/raydium.png')"
                    alt="Raydium Logo"
                    class="h-4 w-4 ml-2"
                />
                <div class="h-8 w-8 flex-shrink-0 bg-white mr-2 ml-2" v-if="pool.imageUrl">
                  <img class="h-8 w-8 rounded-full" :src="pool.imageUrl" alt="" loading="lazy" @error="handleImageError" />
                </div>
                <div class="h-8 w-8 flex-shrink-0 bg-white mr-2 ml-2" v-else></div>
                <div class="flex flex-col overflow-hidden">
                  <div class="font-medium text-gray-900 truncate w-36">
                    <strong>{{ pool.name }}</strong>
                    <span class="text-xs text-gray-500">/ SOL</span>
                  </div>
                  <div class="text-gray-500 text-xs flex items-center">
                    <div class="relative mr-2 flex items-center">
                      <strong class="mr-1">{{ formatAddress(pool.baseTokenAddress) }}</strong>
                      <button @click.stop="$emit('copyToClipboard', pool)" class="copy-button">
                        <i class="fas fa-copy text-gray-500"></i>
                      </button>
                      <span v-if="pool.copied" class="tooltip">Copied!</span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="whitespace-nowrap py-2 text-xs text-left relative w-28">
              <div class="inline-block bg-gray-100 rounded-md p-1">
                <div class="w-20 h-6 rounded-md p-1 flex items-center justify-center">
                  <button @click.stop="$emit('buyToken', pool)" class="relative w-full h-full bg-transparent rounded-md flex items-center justify-center">
                    <span class="mr-3">⚡️</span>
                    <img src="https://cryptologos.cc/logos/solana-sol-logo.png?v=014" alt="SOL" class="w-4 h-4 mr-3">
                    <span class="text-blue-500 font-bold text-sm">{{ newQuickBuyAmount }}</span>
                  </button>
                </div>
              </div>
            </td>
            <td class="whitespace-nowrap py-2 text-sm text-left w-24">
              <div class="text-gray-900 text-lg font-bold">{{ pool.price }}</div>
              <div class="text-gray-500 text-xs mt-1">${{ pool.price }}</div>
            </td>
            <td class="whitespace-nowrap py-2 text-sm text-left w-28">
              <div class="text-gray-900"><strong>{{ pool.poolCreatedTime }}</strong></div>
            </td>
            <td class="whitespace-nowrap py-2 text-left pr-6">
              <SecurityAnalysis :tokenInfo="pool.tokenInfo" />
            </td>
            <td class="whitespace-nowrap py-2 text-sm text-left w-28">
              <div class="text-gray-900"><strong>${{ pool.reserve }}</strong></div>
              <div class="flex items-left mt-1">
                <img src="https://cryptologos.cc/logos/solana-sol-logo.png?v=014" alt="SOL" class="w-4 h-4 mr-1">
                <div class="text-xs text-purple-400"><strong>{{ pool.solNum }}</strong></div>
              </div>
            </td>
            <td class="whitespace-nowrap py-2 text-sm text-left w-24">
              <div class="text-gray-900"><strong>${{ pool.fdv_usd }}</strong></div>
            </td>
            <td class="whitespace-nowrap py-2 text-sm text-left w-24">
              <div class="text-gray-900"><strong>{{ pool.buys24h }}/${{ pool.volume24h }}</strong></div>
              <div class="text-xs">
                <span class="text-green-700"><strong>{{ pool.buys }}</strong></span> / <span class="text-red-500"><strong>{{ pool.sells }}</strong></span>
              </div>
            </td>
            <td v-if="showDetailsIndex !== index" class="whitespace-nowrap py-2 text-sm text-left w-20" :style="{ color: pool.priceChange5mColor }">
              <strong>{{ removeNegativeSign(pool.priceChange5m) }}%</strong>
            </td>
            <td v-if="showDetailsIndex !== index" class="whitespace-nowrap py-2 text-sm text-left w-20" :style="{ color: pool.priceChange1hColor }">
              <strong>{{ removeNegativeSign(pool.priceChange1h) }}%</strong>
            </td>
            <td v-if="showDetailsIndex !== index" class="whitespace-nowrap py-2 text-sm text-left w-20" :style="{ color: pool.priceChange6hColor }">
              <strong>{{ removeNegativeSign(pool.priceChange6h) }}%</strong>
            </td>
            <td v-if="showDetailsIndex !== index" class="whitespace-nowrap py-2 text-sm text-left w-20" :style="{ color: pool.priceChange24hColor }">
              <strong>{{ removeNegativeSign(pool.priceChange24h) }}%</strong>
            </td>
            <td v-else class="whitespace-nowrap py-2 text-sm font-light text-left" colspan="6">
              <div class="absolute right-0 top-0 bg-blue-200 bg-opacity-75 w-1/4 h-full z-10 flex justify-center items-center space-x-4">
                <button @click.stop="$emit('showCommunityDetails', pool)" class="text-gray-800 hover:underline font-bold">查看社区含量</button>
                <button @click.stop="$emit('showSmartMoneyDetails', pool)" class="text-gray-800 hover:underline font-bold">查看有哪些 KOL</button>
                <button @click.stop="$emit('showToolsBoxDetail', pool)" class="text-gray-800 hover:underline font-bold">使用工具箱</button>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="!filteredPools.length">
          <tr v-for="n in placeholderRows" :key="'placeholder-' + n" class="cursor-pointer">
            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-left sm:pl-0">
              <div class="flex items-center">
                <div class="h-8 w-8 flex-shrink-0 bg-gray-200 rounded-full animate-pulse"></div>
                <div class="ml-4">
                  <div class="h-4 bg-gray-200 rounded w-20 animate-pulse"></div>
                  <div class="mt-1 h-3 bg-gray-200 rounded w-16 animate-pulse"></div>
                </div>
              </div>
            </td>
            <td class="whitespace-nowrap py-2 text-xs text-left relative">
              <div class="w-20 h-6 bg-gray-200 rounded-md animate-pulse"></div>
            </td>
            <td class="whitespace-nowrap py-2 text-sm text-left">
              <div class="h-5 bg-gray-200 rounded w-16 animate-pulse"></div>
              <div class="h-3 bg-gray-200 rounded w-12 mt-1 animate-pulse"></div>
            </td>
            <td class="whitespace-nowrap py-2 text-sm text-left">
              <div class="h-4 bg-gray-200 rounded w-14 animate-pulse"></div>
            </td>
            <td class="whitespace-nowrap py-2 text-left pr-6">
              <div class="h-4 bg-gray-200 rounded w-20 animate-pulse"></div>
            </td>
            <td class="whitespace-nowrap py-2 text-sm text-left">
              <div class="h-4 bg-gray-200 rounded w-16 animate-pulse"></div>
              <div class="h-3 bg-gray-200 rounded w-12 mt-1 animate-pulse"></div>
            </td>
            <td class="whitespace-nowrap py-2 text-sm text-left">
              <div class="h-4 bg-gray-200 rounded w-16 animate-pulse"></div>
            </td>
            <td class="whitespace-nowrap py-2 text-sm text-left">
              <div class="h-4 bg-gray-200 rounded w-20 animate-pulse"></div>
              <div class="h-3 bg-gray-200 rounded w-16 mt-1 animate-pulse"></div>
            </td>
            <td v-for="i in 4" :key="'change-' + i" class="whitespace-nowrap py-2 text-sm text-left">
              <div class="h-4 bg-gray-200 rounded w-12 animate-pulse"></div>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import SecurityAnalysis from './SecurityAnalysis.vue';

const props = defineProps({
  filteredPools: Array,
  showDetailsIndex: Number,
  newQuickBuyAmount: Number,
  placeholderRows: {
    type: Number,
    default: 10
  },
  sortConfig: Object
});

const emit = defineEmits([
  'showCommunityDetails',
  'showSmartMoneyDetails',
  'showToolsBoxDetail',
  'handleMouseOver',
  'handleMouseLeave',
  'navigateTo',
  'copyToClipboard',
  'buyToken',
  'toggleSort'
]);

const tableHeaders = [
  { key: 'name', label: '币种', sortable: false },
  { key: 'quickBuy', label: '快速购买', sortable: false },
  { key: 'price', label: '价格', sortable: false },
  { key: 'poolCreatedTime', label: '创建', sortable: true },
  { key: 'security', label: '安全分析', sortable: false },
  { key: 'solNum', label: '池子', sortable: true },
  { key: 'fdv_usd', label: 'FDV', sortable: true },
  { key: 'volume24h', label: '24h交易', sortable: true },
  { key: 'priceChange5m', label: '5m', sortable: true },
  { key: 'priceChange1h', label: '1h', sortable: true },
  { key: 'priceChange6h', label: '6h', sortable: true },
  { key: 'priceChange24h', label: '24h', sortable: true }
];

const formatAddress = (address) => {
  if (!address) return '';
  const firstPart = address.slice(0, 6);
  const lastPart = address.slice(-4);
  return `${firstPart}...${lastPart}`;
};

const removeNegativeSign = (value) => {
  return value;
};

const handleImageError = (event) => {
  event.target.style.display = 'none';
};
</script>

<style scoped>
.hovered-row {
  background-color: #f0f8ff;
}
.selected-row {
  background-color: #f0f8ff;
}
.fire-container {
  display: inline-flex;
  align-items: center;
}
.fire {
  display: inline-block;
  position: relative;
  font-size: 1.5rem;
  animation: burn 1s infinite;
}
@keyframes burn {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}
.fire::before,
.fire::after {
  content: '🔥';
  position: absolute;
  top: 0;
  left: 0;
  animation: flicker 0.5s infinite alternate;
}
.fire::before {
  transform: translate(-10%, -10%) scale(0.8);
  opacity: 0.6;
}
.fire::after {
  transform: translate(10%, 10%) scale(0.8);
  opacity: 0.6;
}
.copy-button {
  background: none;
  border: none;
  cursor: pointer;
}
.tooltip {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 10px;
  opacity: 0.8;
}
.animate-pulse {
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>