<!-- MainModule.vue -->
<template>
  <div class="mt-2 flex flex-col font-heiti">
    <div class="flex items-center mb-1 mt-4">
      <NewCoinQuickBuy @update:amount="handleUpdateAmount"></NewCoinQuickBuy>
    </div>
    <div class="mb-2"></div>

    <hr class="border-gray-300 w-full">

    <PoolTable
        :filteredPools="sortedPools"
        :showDetailsIndex="showDetailsIndex"
        :newQuickBuyAmount="newQuickBuyAmount"
        :sortConfig="sortConfig"
        @showCommunityDetails="showCommunityDetails"
        @showSmartMoneyDetails="showSmartMoneyDetails"
        @showToolsBoxDetail="showToolsBoxDetail"
        @handleMouseOver="handleMouseOver"
        @handleMouseLeave="handleMouseLeave"
        @navigateTo="navigateTo"
        @copyToClipboard="copyToClipboard"
        @buyToken="buyToken"
        @toggleSort="toggleSort"
    ></PoolTable>

    <SlideOver :open="isSlideOverOpen" @close="toggleSlideOver">
      <CalCommunityInclude :poolAddress="currentPoolAddress"></CalCommunityInclude>
    </SlideOver>

    <SlideOver :open="isSmartMoneyCheckOpen" @close="toggleSmartMoneySlideOver">
      <UserAnalysis :poolAddress="currentPoolAddress"></UserAnalysis>
    </SlideOver>

    <SlideOver :open="isToolsBoxOpen" @close="toggleToolsBoxSlideOver">
      <ToolsBox :poolAddress="currentPoolAddress"></ToolsBox>
    </SlideOver>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, toRefs, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import NewCoinQuickBuy from "@/components/projects/NewCoinQuickBuy.vue";
import SlideOver from "@/components/projects/SlideOver.vue";
import CalCommunityInclude from "@/components/projects/CalCommunityInclude.vue";
import UserAnalysis from "@/views/trans/UserAnalysis.vue";
import ToolsBox from "@/views/trans/ToolsBox.vue";
import PoolTable from './PoolTable.vue';

const router = useRouter();
const isSlideOverOpen = ref(false);
const isSmartMoneyCheckOpen = ref(false);
const isToolsBoxOpen = ref(false);
const newQuickBuyAmount = ref(1);
const currentPoolAddress = ref('');
const poolDataArray = ref([]);
const filteredPools = ref([]);
const showDetailsIndex = ref(null);
const isInitialLoad = ref(true);

const sortConfig = ref({
  key: '',
  direction: 'asc'
});

let delayTimeout;
const isDelayActive = ref(false);

const props = defineProps({
  filters: Object
});

const { filters } = toRefs(props);

const toggleSlideOver = () => {
  isSlideOverOpen.value = !isSlideOverOpen.value;
};

const toggleSmartMoneySlideOver = () => {
  isSmartMoneyCheckOpen.value = !isSmartMoneyCheckOpen.value;
};

const toggleToolsBoxSlideOver = () => {
  isToolsBoxOpen.value = !isToolsBoxOpen.value;
};

const showCommunityDetails = (pool) => {
  currentPoolAddress.value = pool.poolAddress;
  toggleSlideOver();
};

const showSmartMoneyDetails = (pool) => {
  currentPoolAddress.value = pool.poolAddress;
  toggleSmartMoneySlideOver();
};

const showToolsBoxDetail = (pool) => {
  currentPoolAddress.value = pool.poolAddress;
  toggleToolsBoxSlideOver();
};

const handleMouseOver = (index) => {
  showDetailsIndex.value = index;
  cancelDelay();
};

const handleMouseLeave = () => {
  startDelay();
};

const startDelay = () => {
  delayTimeout = setTimeout(() => {
    showDetailsIndex.value = null;
  }, 500);
  isDelayActive.value = true;
};

const cancelDelay = () => {
  clearTimeout(delayTimeout);
  isDelayActive.value = false;
};

const handleUpdateAmount = (amount) => {
  newQuickBuyAmount.value = amount;
};

const copyToClipboard = (pool) => {
  navigator.clipboard.writeText(pool.baseTokenAddress).then(() => {
    pool.copied = true;
    setTimeout(() => {
      pool.copied = false;
    }, 2000);
  });
};

const buyToken = (pool) => {
  // Implement buy token logic here
  console.log('Buying token:', pool);
};

const fetchPoolsData = async () => {
  try {
    const response = await axios.get('https://api.memego.ai/api/sol/newPairs');
    const newPools = response.data.poolsData || [];

    poolDataArray.value = newPools.map(newPool => {
      const existingPool = poolDataArray.value.find(p => p.baseTokenAddress === newPool.baseTokenAddress);
      return {
        ...newPool,
        tokenInfo: existingPool ? existingPool.tokenInfo : (isInitialLoad.value ? {
          isMintable: '加载中',
          isDelegated: '加载中',
          canBeRevokedOrFrozen: '加载中'
        } : {
          isMintable: null,
          isDelegated: null,
          canBeRevokedOrFrozen: null
        })
      };
    });

    const tokenInfoPromises = poolDataArray.value.map(async (pool, index) => {
      if (isInitialLoad.value || !pool.tokenInfo || (pool.tokenInfo.isMintable === null && pool.tokenInfo.isDelegated === null && pool.tokenInfo.canBeRevokedOrFrozen === null)) {
        try {
          const tokenInfoResponse = await axios.get(`https://api.memego.ai/api/sol/tokenInfo?addressList=${pool.baseTokenAddress}`);
          const newTokenInfo = tokenInfoResponse.data.tokenInfoList[0];

          console.info(JSON.stringify(newTokenInfo))

          if (newTokenInfo && typeof newTokenInfo.isMintable === 'boolean' && typeof newTokenInfo.isDelegated === 'boolean' && typeof newTokenInfo.canBeRevokedOrFrozen === 'boolean') {
            return { index, tokenInfo: newTokenInfo };
          }
        } catch (error) {
          console.error(`Error fetching token info for ${pool.baseTokenAddress}:`, error);
        }
      }
      return null;
    });

    const results = await Promise.all(tokenInfoPromises);

    results.forEach(result => {
      if (result && result.tokenInfo) {
        poolDataArray.value[result.index].tokenInfo = result.tokenInfo;
      }
    });

    poolDataArray.value = [...poolDataArray.value];

    isInitialLoad.value = false;

    filterPools();
  } catch (error) {
    console.error('Error fetching the pool data:', error);
  }
};

const navigateTo = (pool) => {
  router.push({ path: `/sol_lp/${pool.poolAddress}` });
};

const filterPools = () => {
  let filtered = poolDataArray.value;
  const poolSizeFilter = filters.value.poolSize;
  const poolSizeSelected = filters.value.poolSizeSelected;
  const createTimeSelected = filters.value.createTimeSelected;
  const creationTimeFilter = filters.value.creationTime;

  if (poolSizeFilter && poolSizeSelected) {
    const sizeThresholds = {
      'poolSize10K': 10_000,
      'poolSize50K': 50_000,
      'poolSize100K': 100_000,
      'poolSize500K': 500_000,
    };

    filtered = filtered.filter(pool => {
      const reserveValue = parseFloat(pool.reserve.replace(/[KMB]/, ''));
      const unit = pool.reserve.slice(-1);
      let reserveInUSD;

      if (unit === 'K') {
        reserveInUSD = reserveValue * 1_000;
      } else if (unit === 'M') {
        reserveInUSD = reserveValue * 1_000_000;
      } else if (unit === 'B') {
        reserveInUSD = reserveValue * 1_000_000_000;
      } else {
        reserveInUSD = reserveValue;
      }
      return reserveInUSD > sizeThresholds[poolSizeFilter];
    });
  }

  if (creationTimeFilter && createTimeSelected) {
    const timeThresholds = {
      'recent5m': 5,
      'recent10m': 10,
      'recent30m': 30,
      'recent100m': 100
    };

    const threshold = timeThresholds[creationTimeFilter];
    const currentTime = new Date();

    filtered = filtered.filter(pool => {
      const minutesAgo = parseInt(pool.poolCreatedTime.replace(/[mhd]/, ''), 10);
      return minutesAgo <= threshold;
    });
  }

  filteredPools.value = filtered;
};

const sortedPools = computed(() => {
  const sortedArray = [...filteredPools.value];
  if (sortConfig.value.key) {
    sortedArray.sort((a, b) => {
      let aValue = a[sortConfig.value.key];
      let bValue = b[sortConfig.value.key];

      // 特殊处理 solNum
      if (sortConfig.value.key === 'solNum') {
        aValue = parseFloat(a.solNum);
        bValue = parseFloat(b.solNum);
      }
      // 特殊处理 poolCreatedTime
      else if (sortConfig.value.key === 'poolCreatedTime') {
        aValue = parseInt(a.poolCreatedTime.replace(/[mhd]/, ''), 10);
        bValue = parseInt(b.poolCreatedTime.replace(/[mhd]/, ''), 10);
      }
      // 特殊处理 fdv_usd 和 volume24h
      else if (sortConfig.value.key === 'fdv_usd' || sortConfig.value.key === 'volume24h') {
        aValue = parseFloat(aValue.replace(/[$,]/g, ''));
        bValue = parseFloat(bValue.replace(/[$,]/g, ''));
      }
      // 特殊处理价格变化百分比
      else if (['priceChange5m', 'priceChange1h', 'priceChange6h', 'priceChange24h'].includes(sortConfig.value.key)) {
        aValue = parseFloat(aValue.replace('%', ''));
        bValue = parseFloat(bValue.replace('%', ''));
      }

      if (aValue < bValue) return sortConfig.value.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.value.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }
  return sortedArray;
});

const toggleSort = (key) => {
  if (sortConfig.value.key === key) {
    sortConfig.value.direction = sortConfig.value.direction === 'asc' ? 'desc' : 'asc';
  } else {
    sortConfig.value.key = key;
    sortConfig.value.direction = 'asc';
  }
};

watch(filters, filterPools, { deep: true });

let fetchDataInterval;

onMounted(() => {
  fetchPoolsData();
  fetchDataInterval = setInterval(fetchPoolsData, 20000);
});

onUnmounted(() => {
  if (fetchDataInterval) {
    clearInterval(fetchDataInterval);
  }
});
</script>

<style scoped>
.font-heiti {
  font-family: 'HeiTi', sans-serif;
}
</style>