<template>
  <div class="mx-auto px-1">
    <div class="flex">
      <ETHNewSidebar class="w-1/7 mr-4 mt-4" @selectionChange="handleSelectionChange" />
      <div class="flex-grow pr-4">
        <NewPairs :filters="filters" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import NewPairs from './NewPairs.vue';
import ETHNewSidebar from "@/views/ETHNewSidebar.vue";

const filters = ref({
  poolSize: null,
  creationTime: null,
  poolSizeSelected: false,
  createTimeSelected: false
});

const handleSelectionChange = (selection) => {
  if (selection.category === '池子大小') {
    filters.value.poolSize = selection.option;
    filters.value.poolSizeSelected = selection.selected;
  } else if (selection.category === '创建时间') {
    filters.value.creationTime = selection.option;
    filters.value.createTimeSelected = selection.selected;
  }
};
</script>

<style scoped>
.container {
  margin: 0;
  padding: 0 16px;
  width: calc(100% - 32px);
  box-sizing: border-box;
}
</style>

<style>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}
</style>