<template>
  <div class="mx-auto px-1" @click="handleClickOutside">
    <div class="flex">
      <Sidebar class="w-1/7 mr-4 mt-4" @selectionChange="handleSelectionChange" />
      <div class="flex-grow pr-4">
        <TokenTable :filters="filters" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import TokenTable from './SolNewPairs.vue';
import Sidebar from './Sidebar.vue';
import Pagination from './Pagination.vue';

const filters = ref({
  poolSize: null,
  creationTime: null,
  smartMoneyCount: null,
  devSource: null,
  community: []
});

const handleSelectionChange = (selection) => {
  console.log('Selection changed:', selection);
  if (selection.category === '池子大小') {
    filters.value.poolSize = selection.option;
    filters.value.poolSizeSelected = selection.selected;
  } else if (selection.category === '创建时间') {
    filters.value.creationTime = selection.option;
    filters.value.createTimeSelected = selection.selected;
  }
  // 其他筛选条件处理逻辑
};
</script>

<style scoped>
.container {
  margin: 0;
  padding: 0 16px;
  width: calc(100% - 32px);
  box-sizing: border-box;
}
</style>

<style>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}
</style>