<template>
  <div v-if="open" class="fixed inset-0 z-50 overflow-hidden flex items-center justify-center">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" @click="close"></div>
    <div class="bg-white w-3/4 max-w-4xl p-8 rounded-lg shadow-lg relative z-50">
      <button @click="close" class="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none">
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
      <div class="overflow-y-auto max-h-full" @click.stop>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
/* Custom styles if needed */
</style>