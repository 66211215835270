<template>
  <div class="relative flex flex-col items-center">
    <div :class="['relative transition-all duration-300', isFocused || searchQuery ? 'w-128' : 'w-96']">
      <input
          ref="inputBox"
          type="text"
          v-model="searchQuery"
          placeholder="输入代币名、用户地址、LP、Token地址"
          class="w-full pl-10 pr-10 py-2 border rounded-md dark:bg-gray-800 dark:text-white"
          @input="debouncedHandleInput"
          @focus="handleFocus"
          @blur="handleBlur"
      />
      <i class="fas fa-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>

      <!-- X button for clearing input -->
      <i
          v-if="searchQuery"
          class="fas fa-times absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer hover:text-gray-600"
          @click="clearInput"
      ></i>

      <ul
          v-if="isDropdownVisible && sortedTokenDetails.length"
          class="absolute z-50 w-full bg-white border-none rounded-md shadow-md mt-1 dark:bg-gray-800 dropdown overflow-y-auto"
          style="max-height: 800px;"
      >
        <li
            v-for="detail in sortedTokenDetails"
            :key="detail.pairAddress || detail.hash"
            @mousedown="selectToken(detail)"
            class="px-2 py-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 bg-white rounded-lg space-y-2 flex flex-col items-start justify-between"
            style="margin-bottom: 8px; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);"
        >
          <!-- Dropdown content -->
          <div class="grid grid-cols-[auto_1.5fr_1fr_1fr_1fr_1fr] gap-x-2 items-center">
            <div class="flex flex-col items-center">
              <!-- Only show logos if both chain and dex conditions are met -->
              <template v-if="shouldShowLogos(detail.chainId, detail.dexId)">
                <img
                    :src="getChainLogo(detail.chainId)"
                    class="h-4 w-4 mb-1"
                    alt="Chain Logo"
                />
                <img
                    :src="getDexLogo(detail.dexId)"
                    class="h-4 w-4"
                    alt="Dex Logo"
                />
              </template>
            </div>

            <div class="flex items-center">
              <img
                  :src="detail.info?.imageUrl || defaultImage"
                  class="h-10 w-10 rounded-full"
                  alt="Token Avatar"
                  @error="handleImageError"
              />
              <div class="ml-2 text-left">
                <p class="text-sm font-bold text-gray-900 dark:text-gray-100">
                  {{ detail.baseToken?.symbol || detail.ensDomainName || formatAddress(detail.hash) }}
                </p>
                <p v-if="detail.priceUsd" class="text-sm text-gray-500 dark:text-gray-400">
                  <span class="font-bold"></span> <span class="font-normal">{{ formatPrice(detail.priceUsd) }}</span>
                </p>
              </div>
            </div>

            <div class="whitespace-nowrap text-left">
              <p v-if="detail.liquidity" class="text-sm">
                <span class="font-bold">池子:</span> <span class="font-normal">{{ formatLiquidity(detail.liquidity.usd) }}</span>
              </p>
              <p v-if="detail.pairCreatedAt" class="text-sm text-gray-500 dark:text-gray-400">
                <span class="font-bold">创建:</span> <span class="font-normal">{{ formatTime(detail.pairCreatedAt) }}</span>
              </p>
            </div>

            <div class="whitespace-nowrap text-left">
              <p v-if="detail.priceChange.h1" class="text-sm font-bold text-black">
                1h: <span :class="getPriceChangeClass(detail.priceChange.h1)">{{ detail.priceChange.h1 }}%</span>
              </p>
              <p v-if="detail.priceChange.h24" class="text-sm font-bold text-black">
                24h: <span :class="getPriceChangeClass(detail.priceChange.h24)">{{ detail.priceChange.h24 }}%</span>
              </p>
            </div>

            <div class="whitespace-nowrap text-left">
              <p v-if="detail.txns.h1" class="text-sm text-gray-900">
                <span class="font-bold">Tx:</span> <span class="font-normal">{{ formatToKMB(detail.txns.h1.buys + detail.txns.h1.sells) }}</span>
              </p>
              <p v-if="detail.txns.h24" class="text-sm text-gray-900">
                <span class="font-bold">Tx:</span> <span class="font-normal">{{ formatToKMB(detail.txns.h24.buys + detail.txns.h24.sells) }}</span>
              </p>
            </div>

            <div class="whitespace-nowrap text-left">
              <p v-if="detail.volume.h1" class="text-sm text-gray-900">
                <span class="font-bold">Vol:</span> <span class="font-normal">${{ formatToKMB(detail.volume.h1) }}</span>
              </p>
              <p v-if="detail.volume.h24" class="text-sm text-gray-900">
                <span class="font-bold">Vol:</span> <span class="font-normal">${{ formatToKMB(detail.volume.h24) }}</span>
              </p>
            </div>
          </div>

          <div class="flex justify-start w-full pt-2 mt-2">
            <p class="text-xs text-gray-500 mr-2">Pair: {{ formatAddress(detail.pairAddress) }}</p>
            <p class="text-xs text-gray-500">Token: {{ formatAddress(detail.baseToken.address) }}</p>
          </div>
        </li>
      </ul>
      <div
          v-if="loading"
          class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 dark:bg-gray-800 dark:bg-opacity-75"
      >
        <span class="text-gray-500 dark:text-gray-300">加载中...</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import debounce from 'lodash/debounce'

const store = inject('store')
const searchQuery = ref('')
const isDropdownVisible = ref(false)
const tokenDetails = ref([])
const loading = ref(false)
const cache = new Map()
const router = useRouter()
const defaultImage = 'https://pbs.twimg.com/profile_images/1645239879541092353/sUnD66av_400x400.jpg'
const isFocused = ref(false)
const inputBox = ref(null)

const selectedComponent = computed(() => {
  return store.selected.name === 'SOL' ? 'solana' : 'ethereum'
})

watch(() => store.selected, (newVal) => {
  fetchTokenDetails(searchQuery.value)
})

const sortedTokenDetails = computed(() => {
  return [...tokenDetails.value].sort((a, b) => b.liquidity.usd - a.liquidity.usd);
})

function getChainLogo(chainId) {
  switch (chainId) {
    case 'solana':
      return require('@/assets/logo/sol.png');
    case 'ethereum':
      return require('@/assets/logo/eth.png');
    case 'bsc':
      return require('@/assets/logo/bnb.png');
    case 'base':
      return require('@/assets/logo/base.png');
    default:
      return null; // 不显示图标
  }
}

function getDexLogo(dexId) {
  switch (dexId) {
    case 'raydium':
      return require('@/assets/logo/raydium.png');
    case 'uniswap':
      return require('@/assets/logo/uniswap.png');
    case 'pancakeswap':
      return require('@/assets/logo/cake.png');
    default:
      return null; // 不显示图标
  }
}

// Function to check if both chainId and dexId match conditions and return valid logos
function shouldShowLogos(chainId, dexId) {
  const chainLogo = getChainLogo(chainId);
  const dexLogo = getDexLogo(dexId);
  return chainLogo && dexLogo; // Only show logos if both are valid
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})

function handleClickOutside(event) {
  if (!inputBox.value.contains(event.target) && !event.target.closest('.dropdown')) {
    isDropdownVisible.value = false
  }
}

// 定义支持的链和DEX列表
const supportedChains = ['solana', 'ethereum', 'bsc', 'base'];
const supportedDexes = ['raydium', 'uniswap', 'pancakeswap'];

async function fetchTokenDetails(query) {
  const chain = selectedComponent.value;
  const apiUrl = chain === 'solana'
      ? `https://api.memego.ai/api/sol/search?q=${query}`
      : `https://api.memego.ai/api/eth/search?q=${query}`;

  if (cache.has(query)) {
    tokenDetails.value = cache.get(query);
    loading.value = false;
    return;
  }

  try {
    loading.value = true;
    const response = await axios.get(apiUrl);
    const details = response.data.results
        .filter(pair => supportedChains.includes(pair.chainId)) // 检查是否在支持列表里
        .map(pair => ({
      type: pair.type,
      chainId: pair.chainId,
      dexId: pair.dexId,
      baseToken: {
        name: pair.baseToken.name,
        symbol: pair.baseToken.symbol,
        address: pair.baseToken.address
      },
      quoteToken: {
        name: pair.quoteToken.name,
        symbol: pair.quoteToken.symbol,
        address: pair.quoteToken.address
      },
      priceUsd: pair.priceUsd,
      priceNative: pair.priceNative,
      liquidity: pair.liquidity,
      pairCreatedAt: pair.pairCreatedAt,
      pairAddress: pair.pairAddress,
      info: pair.info,
      txns: pair.txns,
      volume: pair.volume,
      priceChange: pair.priceChange,
      fdv: pair.fdv,
    }));

    tokenDetails.value = details;
    cache.set(query, details);
  } catch (error) {
    console.error('Error fetching token details:', error);
    tokenDetails.value = [];
  } finally {
    loading.value = false;
  }
}

function handleInput() {
  if (searchQuery.value) {
    isDropdownVisible.value = true
    fetchTokenDetails(searchQuery.value)
  }
}

const debouncedHandleInput = debounce(handleInput, 300)

function selectToken(token) {
  if (token.type === 'pool' && token.pairAddress) {
    switch (token.chainId) {
      case 'solana':
        router.push({ path: `/sol_lp/${token.pairAddress.toLowerCase()}` });
        break;
      case 'ethereum':
        router.push({ path: `/lp/${token.pairAddress.toLowerCase()}` });
        break;
      case 'base':
        router.push({ path: `/base_lp/${token.pairAddress.toLowerCase()}` });
        break;
      default:
        console.warn('Unsupported dexId:', token.chainId);
    }
  } else {
    searchQuery.value = token.baseToken?.name || token.ensDomainName || formatAddress(token.hash);
  }
  isDropdownVisible.value = false;
}


function handleFocus() {
  isFocused.value = true
}

function handleBlur() {
  setTimeout(() => {
    if (!searchQuery.value) {
      isFocused.value = false;
    }
  }, 200);
}

function clearInput() {
  searchQuery.value = '';
  isDropdownVisible.value = false;
}

function handleImageError(event) {
  event.target.src = defaultImage
}

function formatAddress(addr) {
  return `${addr.slice(0, 6)}...${addr.slice(-4)}`
}

function getPriceChangeClass(value) {
  return value >= 0 ? 'text-green-500' : 'text-red-500'
}

function formatPrice(price) {
  return `${Number(price).toFixed(5)}`;
}

function formatLiquidity(value) {
  if (value >= 1000000) return (value / 1000000).toFixed(1) + 'M'
  if (value >= 1000) return (value / 1000).toFixed(1) + 'K'
  return value
}

function formatTime(timestamp) {
  const now = new Date()
  const timeDifference = now - new Date(timestamp)

  const minutes = Math.floor(timeDifference / (1000 * 60))
  const hours = Math.floor(timeDifference / (1000 * 60 * 60))
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

  if (days > 0) {
    return `${days}D`
  } else if (hours > 0) {
    return `${hours}h`
  } else {
    return `${minutes}m`
  }
}

function formatToKMB(number) {
  if (number >= 1000000000) {
    return (number / 1000000000).toFixed(0) + 'B';
  } else if (number >= 1000000) {
    return (number / 1000000).toFixed(0) + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(0) + 'K';
  } else {
    return number;
  }
}
</script>

<style scoped>
.w-128 {
  width: 32rem;
}

.w-96 {
  width: 24rem;
}

.transition-all {
  transition: all 0.3s ease-in-out;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.dropdown {
  z-index: 9999 !important;
}

.space-x-4 {
  margin-left: 1rem;
}
</style>
