<script setup>
import { ref, onMounted, onUpdated, inject, computed } from 'vue';
import feather from 'feather-icons';
import AppHeaderLinks from './AppHeaderLinks.vue';
import SolanaWalletLogin from "@/components/shared/SolanaWalletLogin.vue";
import ETHWalletLogin from "@/components/shared/ETHWalletLogin.vue";
import BaseWalletLogin from "@/components/shared/BaseWalletLogin.vue";
import SelectMenus from "@/components/shared/SelectMenus.vue";

// Define component
const isOpen = ref(false);
const theme = ref(localStorage.getItem('theme') || 'light');
const modal = ref(false);
const categories = [];

// Store injection
const store = inject('store'); // 确保你的 store 包含 selected 的状态
const selected = computed(() => store.selected); // 计算属性获取当前选中的选项

// Lifecycle hooks
onMounted(() => {
  feather.replace();
  theme.value = localStorage.getItem('theme') || 'light';
});

onUpdated(() => {
  feather.replace();
});

const showModal = () => {
  const html = document.getElementsByTagName('html')[0];
  if (modal.value) {
    // Stop screen scrolling
    html.classList.remove('overflow-y-hidden');
    modal.value = false;
  } else {
    html.classList.add('overflow-y-hidden');
    modal.value = true;
  }
};
</script>

<template>
  <nav id="nav" class="layered-background w-full relative h-12">
    <!-- Header start -->
    <div class="z-10 max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:items-center my-1 justify-between h-full px-4">

      <SelectMenus class="select-menus-custom-width mr-10 mb-1"/>

      <!-- Header links -->
      <div class="container mx-auto px-2 sm:px-4 lg:px-6 flex h-full">
        <AppHeaderLinks :showModal="showModal" :isOpen="isOpen"/>
      </div>

      <!-- Wallet, Balance, and Avatar at the right end -->
      <div class="absolute right-0 flex items-center pr-2 h-full">
        <!-- Wallet icon -->
        <!-- Solana Wallet Login -->
        <SolanaWalletLogin v-if="selected && selected.name === 'SOL'" class="mr-4"/>
        <ETHWalletLogin v-if="selected && selected.name === 'ETH'" class="mr-4"/>
        <BaseWalletLogin v-if="selected && selected.name === 'BASE'" class="mr-4"/>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.layered-background {
  background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 50%, #e2e2e2 100%);
  border-bottom: 1px solid #cccccc;
}
.select-menus-custom-width {
  width: 150px; /* 这里设置固定宽度，可以根据需要调整 */
  margin-left: 0.5rem; /* 与左侧有一点间隔 */
  margin-bottom: 0.25rem; /* 与下方有一点间隔 */
}
</style>